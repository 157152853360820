import React, { useReducer, useCallback, useEffect } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { Box, Text, Button } from '@just/jui';
import { Layout, Switch, Loading } from '../components';
import { Trans, t } from '@lingui/macro';
import RowLine from './RowLine';
import RowEditor from './RowEditor';
import FormHeaderEditor from './FormHeaderEditor';
import { Form, Segment } from '../types';
import {
  reducer,
  selectRow,
  insertNewRow,
  hideAnyEditor,
  showFormEditor,
  ROW_EDITOR,
  FORM_EDITOR
} from './reducer';
import { Row } from '../types';


interface Props {
  form: Form;
  onSave?: (f: Form) => void;
  onCancel?: () => void;
}

export default function FormEditor({
  form: originalForm,
  onSave,
  onCancel
}: Props): React.ReactElement<Props> {
  const [{ form, selectedRowId, activeEditor }, dispatch] =
    useReducer(reducer, {
      form: originalForm,
      selectedRowId: -1,
      activeEditor: originalForm.rows.length > 0 ? null : FORM_EDITOR
    });

  const changeSelectedRow = useCallback((row: Row) => {
    dispatch(selectRow(row.row));
  }, [dispatch]);

  const editingRow = activeEditor === ROW_EDITOR;
  const editingForm = activeEditor === FORM_EDITOR;

  const selectSiblingRow = (mod: number) => {
    const { rows } = form;

    let idx = rows.findIndex(r => r.row === selectedRowId) + mod;

    if (idx < 0) idx = 0;
    if (idx >= rows.length) idx = rows.length - 1;

    dispatch(selectRow(rows[idx].row));
  }

  useHotkeys('escape', () => dispatch(hideAnyEditor()));
  useHotkeys('ctrl+s', save);

  useHotkeys('up', () => selectSiblingRow(-1), [selectedRowId, form]);
  useHotkeys('down', () => selectSiblingRow(+1), [selectedRowId, form]);

  return (
    <Layout
      buttons={<>
        <Button icon="done" primary onClick={save}>
          <Trans>Save</Trans>
        </Button>
        <Button icon="clear" onClick={onCancel} margin="s">
          <Trans>Cancel</Trans>
        </Button>
        <Button icon="playlist_add"
                title={t`Add a new row`}
                onClick={newRow}>
          <Trans>New Row</Trans>
        </Button>
      </>}>
      <Box padding="0 0 0 0">
      {
        editingForm
          ? <FormHeaderEditor form={form}
                              dispatch={dispatch} />
          : <Box horizontal
                 border="0 0 w2 0"
                 borderColor="black-2"
                 borderCollapse
                 padding="s 0"
                 spacing="s"
                 onClick={() => dispatch(showFormEditor())}>
              <Text h4 caps center strong width="5">{form.code}</Text>
              <Text h4 caps strong width="30">{form.name}</Text>
              <Text h4 caps center width="5"></Text>
              <Text h4 caps width="40 0@sm">
                <Trans>Formula</Trans>
              </Text>
              <Text h4 caps width="15 0@sm">
                <Trans>Validator</Trans>
              </Text>
            </Box>
      }
      </Box>
      <Box flex="1" overflow="auto" padding="0 0 0 0">
        {
          form.rows.map(row =>
            editingRow && selectedRowId === row.row
              ? <RowEditor row={row}
                           form={form}
                           key={row.row}
                           dispatch={dispatch} />
              : <RowLine row={row}
                         key={row.row}
                         onClick={changeSelectedRow}/>
          )
        }
        {
          !form.rows.length &&
            <Box padding="l">
              <Text block center as="div">
                <Text large block strong padding="m">
                  <Trans>There are no rows defined yet.</Trans>
                </Text>
                <Text color="dark-4" link onClick={newRow}>
                  <Trans>
                    Click here or press the "ADD ROW" button
                    on toolbar below to create a first one.
                  </Trans>
                </Text>
              </Text>
            </Box>
        }
        { (editingRow || editingForm) && <Box overlay="clickthrough" /> }
      </Box>
    </Layout>
  );

  function newRow() {
    dispatch(insertNewRow(0));
  }

  function save() {
    onSave?.({ ...originalForm, ...form });
  }
}
