import React, { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { t, Trans, Plural } from '@lingui/macro';
import useSWR from 'swr';
import { Icon, Text, Box, Dropdown } from '@just/jui';
import AssetSelect from './AssetSelect';
import { FormData, Form, Asset, Map } from '../types';


interface Props {
  dataPath: string;
  pivot: Asset;
  form: Form;
}

export default function FormStatus({
  dataPath,
  pivot,
  form
}: Props): React.ReactElement<Props> {
  const navigate = useNavigate();
  const [segmentsVisible, setSegmentsVisible] = useState(false);

  const { data } = useSWR<FormData[]>(dataPath);
  const { data: checks } = useSWR<FormData[]>(
    pivot.isConsolidator
      ? `${dataPath}/pivots/${pivot.id}/checks`
      : `${dataPath}/checks`
  );

  const empty =
    <Text color="dark-4"
          title={t`Click to create a new report and put some data`}>
      <Trans>N/A</Trans>
    </Text>;

  const status = useMemo(() => data && checks
    ? data.length > 0
      ? pivot.isConsolidator || data.find(d => d.pivotId === pivot.id)
        ? errorsCount(checks.find(d => d.pivotId === pivot.id))
        : empty
      : empty
    : <Icon>sync</Icon>
  , [data, checks]);

  const getSegmentStatus = useMemo(() => {
    if (!data || !checks) return () => <Icon>sync</Icon>;

    const pivotChecks = checks.filter(d => d.pivotId === pivot.id);
    const pivotData = pivot.isConsolidator
      ? data : data.filter(d => d.pivotId === pivot.id);

    return (segmentId: number, active: boolean): React.ReactNode =>
      pivotData.find(d => d.segmentId === segmentId)
        ? errorsCount(pivotChecks.find(d => d.segmentId === segmentId))
        : <Text color={active ? "dark-3" : "light-4"}>
            <Trans>N/A</Trans>
          </Text>;
  }, [data, checks]);

  const isSegmented = !!form.segmentId;

  return isSegmented
    ? <Box width="7"
           background={segmentsVisible && 'dark-2'}
           onClick={showSegments}>
        <Text block small strong center color={segmentsVisible && 'light-1'}>
          {status}
        </Text>

        {data && checks &&
          <Dropdown delay={10}
                    visible={segmentsVisible}
                    onBlur={hideSegments}>
            <AssetSelect segment={form.segmentId!}
                         getStatus={getSegmentStatus}
                         onChange={selectSegment} />
          </Dropdown>
        }
      </Box>
    : <Text block width="7" small strong center>
        <Link to={`${form.code}/${pivot.code}`}>{status}</Link>
      </Text>;

  function showSegments() {
    setSegmentsVisible(true);
  }

  function hideSegments() {
    setSegmentsVisible(false);
  }

  function selectSegment(id?: number, asset?: Asset) {
    setSegmentsVisible(false);
    if (!asset) return;

    navigate(`${form.code}/${pivot.code}/${asset.code}`);
  }
}

function errorsCount(check?: FormData) {
  if (!check) return t`OK`;

  const count = Object.values(onlyResults(check))
                      .filter(r => r !== 0).length;
  return count ?
    <Text error>
      <Plural value={count}
              one="# error"
              other="# errors" />
    </Text>
    : t`OK`;
}

function onlyResults(check: FormData) {
  const {
    scenarioId,
    segmentId,
    pivotId,
    version,
    period,
    ...results
  } = check;

  return results;
}
