import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import {
  utc,
  parsePeriod,
  shiftPeriod,
  ReportPeriod,
  normalizePeriod
} from '../../shared/period';

export {
  utc,
  parsePeriod,
  shiftPeriod,
  normalizePeriod
} from '../../shared/period';

type Cache<T> = { [locale: string]: T };

const DAY_MS = 1000 * 60 * 60 * 24;

const DAY_NAMES: Cache<string[]> = {};
const MONTH_NAMES: Cache<string[]> = {};
const LOCALE_PARTS: Cache<[string, string]> = {};


function getLocaleParts(locale?: string): [string, string] {
  if (!locale) locale = navigator?.language || i18n.locale;

  let result = LOCALE_PARTS[locale];
  if (result) return result;

  const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);

  const group = parts.find(d => d.type === 'group')?.value;
  const decimal = parts.find(d => d.type === 'decimal')?.value;

  result = LOCALE_PARTS[locale] = [group || '', decimal || ''];
  return result;
}

export function periodNames(): { [key in ReportPeriod]: string } {
  return {
    daily: t`daily`,
    weekly: t`weekly`,
    monthly: t`monthly`,
    quarterly: t`quarterly`,
    annually: t`annually`
  };
}

export function monthNames(locale: string = i18n.locale): string[] {
  if (locale in MONTH_NAMES) return MONTH_NAMES[locale];

  const format = new Intl.DateTimeFormat(locale, { month: 'short' }).format;
  const names = MONTH_NAMES[locale] = [...Array(12).keys()]
    .map((m) => format(new Date(Date.UTC(2021, m))));

  return names;
}

export function dayNames(locale: string = i18n.locale): string[] {
  if (locale in DAY_NAMES) return DAY_NAMES[locale];

  const format = new Intl.DateTimeFormat(locale, { weekday: 'short' }).format;
  const names = DAY_NAMES[locale] = [...Array(7).keys()]
    .map((d) => format(new Date(Date.UTC(2021, 5, d))));

  return names;
}

export function isString(object: any): object is string {
  return object && typeof object === 'string';
}

export function isBlank<T>(object: T): boolean {
  for (const key in object) {
    const value = object[key];
    if (!value) continue;
    if (Array.isArray(value) && !value.length) continue;
    if (typeof value === 'object' && !Object.keys(value).length) continue;
    return false;
  }
  return true;
}

export function format(v: number, locale: string = i18n.locale): string {
  return v.toLocaleString(locale);
}

export function parseDecimal(
  v: string,
  locale: string = i18n.locale
): number {
  if (!v) return 0;

  const [group, decimal] = getLocaleParts(locale);
  return parseFloat(v.replaceAll(' ', '')
                     .replaceAll(group, '')
                     .replaceAll(decimal, '.'));
}

export function pad(
  value: number,
  format: '00'|'000'|'0000' = '00'
): string {
  return (format + value).slice(-format.length);
}

export function formatDate(d: Date): string {
  const formatter = new Intl.DateTimeFormat(i18n.locale, {
    day: '2-digit',
    month: 'short',
  });

  return formatter.format(d);
}

export function periodCode(d: Date|string): string {
  if (typeof d === 'string') return d;

  const year = d.getUTCFullYear() - 2000;
  const month = pad(d.getUTCMonth() + 1);
  const date = pad(d.getUTCDate());

  return `${year}${month}${date}`;
}

export function periodLabel(
  periodOrCode: Date|string,
  type: ReportPeriod
): string {
  const period = typeof periodOrCode === 'string' ?
    normalizePeriod(periodOrCode, type)! : periodOrCode;

  const fullYear = period.getUTCFullYear();
  const year = period.getUTCFullYear() - 2000;
  const month = period.getUTCMonth();
  const date = period.getUTCDate();

  switch (type) {
    case 'weekly':
      const start = formatDate(period);
      const end = formatDate(utc(fullYear, month, date + 7));
      return `${year}/${getWeekNumber(period)}W (${start} - ${end})`
    case 'quarterly':
      const quarter = Math.ceil((month + 1) / 3);
      return `${year}/${quarter}Q`
    case 'annually': return `${fullYear}`;
    case 'monthly': return `${year}/${pad(month + 1)}`;
    case 'daily':  return `${year}/${pad(month + 1)}/${pad(date)}`
  }
}

function getWeekNumber(date: Date): number {
  const utcDate = utc(date.getFullYear(), date.getMonth(), date.getDate());

  const dayNum = utcDate.getUTCDay() || 7;
  utcDate.setUTCDate(utcDate.getUTCDate() + 4 - dayNum);

  const yearStart = utc(utcDate.getUTCFullYear());
  return Math.ceil(
    (((utcDate.getTime() - yearStart.getTime()) / DAY_MS) + 1) / 7
  );
}

export function changed<T>(original: T, changes: T): boolean {
  for (const key in changes) {
    if (original[key] !== changes[key]) return true;
  }

  return false;
}

interface Groupable {
  group: string;
}

interface Sortable {
  name: string;
  order?: number;
}

interface Group<T> {
  name: string;
  order?: number;
  items: T[];
}

const byOrderAndName = (a: Sortable, b: Sortable) =>
  a.order === b.order
    ? a.name > b.name ? 1 : -1
     : (a.order || 0) - (b.order || 0);

export function group<T extends Groupable & Sortable>(
  array?: T[]
): Group<T>[] {
  if (!array) return [];

  const groups = array.reduce((groups, item) => {
      const key = item.group;
      const group = groups[key] =
        groups[key] || { order: item.order, items: [], name: key };

      group.order = Math.min(group.order || 0, item.order || 0);
      group.items.push(item);

      return groups;
  }, {} as { [group: string]: Group<T> });

  return Object.values(groups)
    .sort(byOrderAndName)
    .map(g => ({ ...g, items: g.items.sort(byOrderAndName) }));
}
