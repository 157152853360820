import React from 'react';
import useSWR from 'swr';
import { Trans, t } from '@lingui/macro';
import { Link, Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Button
} from '@just/jui';
import { Account, Report } from '../types';
import { Frame, Loading } from '../components';


export default function Accounts(): React.ReactElement{
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data: accounts } = useSWR<Account[]>('/accounts');
  const { data: reports } = useSWR<Report[]>(singleAccountReports);

  if (!accounts) return <Loading />
  if (!accounts.length) return <Navigate to="/accounts/new" replace />

  if (searchParams.has('fastPass') && reports?.length === 1) {
    const [{ id: account }] = accounts;
    const [{ code: report }] = reports;
    return <Navigate to={`/${account}/reports/${report}`} replace />
  }

  return (
    <Frame title={t`Astring Reporting`}>
      <Box margin="m">
        <Text>
          <Trans>
            Select an existing account or
            <Text link>
              <Link to="/accounts/new">
                <Trans>create a new account.</Trans>
              </Link>
            </Text>
          </Trans>
        </Text>
      </Box>
      <Box spacing="s" maxHeight="m" margin="xl">
        {
          accounts.map(({ id }) =>
            <Text block key={id}>
              &bull;&nbsp;
              <Text link caps>
                <Link to={`/${id}/reports`}>{id}</Link>
              </Text>
            </Text>
          )
        }
      </Box>
      <Box horizontal>
        <Button onClick={newAccount}>
          <Trans>Create a new account</Trans>
        </Button>
        <Button onClick={signout}>
          <Trans>Sign-out</Trans>
        </Button>
      </Box>
    </Frame>
  );

  function signout() {
    navigate('/signout');
  }

  function newAccount() {
    navigate('new');
  }

  function singleAccountReports() {
    return accounts?.length === 1
      ? `/accounts/${accounts[0].id}/reports`: null;
  }
}
