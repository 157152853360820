import {
  Dispatch as ReactDispacth,
  createContext,
  useCallback,
  useContext,
  useReducer,
  useMemo
} from 'react';
import { AppState, Locale, Session } from './types';
import { getDefaultLocale } from './i18n';


const blankState: AppState = {
  session: null,
  locale: sessionStorage.locale || getDefaultLocale()
};

type Login = { type: 'LOGIN', payload: Session };
type Logout = { type: 'LOGOUT' };
type SetLocale = { type: 'SET_LOCALE', payload: Locale };

type AppAction = Login|Logout|SetLocale;
type AppDispatch = ReactDispacth<AppAction>;

export const AppContext = createContext<[AppState, AppDispatch]>(
  [blankState, () => {}]
);

export function useAppReducer(): [AppState, AppDispatch] {
  const [state, dispatch] = useReducer(reducer, blankState);
  return useMemo(() => [state, dispatch], [state, dispatch]);
}

export function useAppContext(): [AppState, AppDispatch];
export function useAppContext<T>(select: (state: AppState) => T): T;
export function useAppContext<T>(select?: (state: AppState) => T) {
  const context = useContext(AppContext);
  return select ? select(context[0]) : context;
}

export const login = (s: Session): AppAction =>
  ({ type: 'LOGIN', payload: s });

export const logout = (s: Session): AppAction =>
  ({ type: 'LOGOUT' });

export const setLocale = (locale: Locale): AppAction =>
  ({ type: 'SET_LOCALE', payload: locale });

function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, session: action.payload };
    case 'LOGOUT':
      return { ...state, session: null };
    case 'SET_LOCALE':
      sessionStorage.locale = action.payload;
      window.location.reload();
      return { ...state, locale: action.payload };
    default: return state;
  }
}
