const identity = <T>(value: T): T => value;

export const OPERATOR = 'operator';
export const VARIABLE = 'variable';
export const MODIFIER = 'modifier';
export const CONSTANT = 'constant';
export const EMPTY = 'empty';

export interface Formatter {
  operator?: (o: string) => string;
  variable?: (v: string) => string;
  variableToken?: (v: VariableToken) => string;
  modifier?: (m: string) => string;
  modifierToken?: (m: ModifierToken) => string;
  constant?: (c: string) => string;
  argument?: (a: string) => string;
  empty?: (e: string) => string;
}

interface BaseToken { token: string; index?: number }
export interface EmptyToken extends BaseToken {
  type: typeof EMPTY;
}
export interface OperatorToken extends BaseToken {
  type: typeof OPERATOR;
}
export interface VariableToken extends BaseToken  {
  type: typeof VARIABLE;
  modifiers: ModifierToken[];
}
export interface ModifierToken extends BaseToken  {
  type: typeof MODIFIER;
  argument?: string;
}
export interface ConstantToken extends BaseToken  {
  type: typeof CONSTANT;
}

export type TokenType =
  | typeof OPERATOR
  | typeof VARIABLE
  | typeof MODIFIER
  | typeof CONSTANT
  | typeof EMPTY;

export type Token =
  EmptyToken|OperatorToken|VariableToken|ModifierToken|ConstantToken;

export function getTokenAt(
  text: string,
  at: number = text.length
): { text: string; type: TokenType }|null {
  const tokens = parse(text);

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    const next = tokens[i + 1];

    if (token.index === undefined || next.index === undefined) continue;

    if (!next || (token.index >= at && at < next.index))
      return { type: token.type, text: token.token };
  }

  return null;
}

export function format(expression: string, formatter: Formatter): string {
  const tokens = parse(expression);
  return print(tokens, formatter);
}

export function print(t: Token|Token[], format: Formatter = {}): string {
  if (Array.isArray(t)) return t.map(t => print(t, format)).join('');

  const { token } = t;
  const {
    variable = identity,
    modifier = identity,
    argument = identity,
    constant = identity,
    operator = identity,
    empty = identity,

    modifierToken = (m => `${modifier(m.token)}` +
                          `[${argument(m.argument || '')}]`),

    variableToken = (v => v.modifiers.map(m => print(m, format))
                                     .concat(variable(v.token))
                                     .join('.'))
  } = format;

  switch (t.type) {
    case VARIABLE:
      return variableToken(t);
    case MODIFIER:
      return modifierToken(t);
    case OPERATOR:
      return operator(token);
    case CONSTANT:
      return constant(token);
    case EMPTY:
      return empty(token);
    default:
      return token;
  }
}

export function parse(text: string): Token[] {
  const operators = '()+-/';

  let index = 0;
  let token = '';
  let escaping = false;

  let tokens: Token[] = [];
  let modifiers: ModifierToken[] = [];

  for (let i = 0; i <= text.length; i++) {
    const char = text[i];

    switch (true) {
      case !char:
        pushToken();
        break;
      case !escaping && operators.includes(char):
        pushToken();
        pushOperator(char);
        break;
      case !escaping && char === '.':
        pushModifier();
        break;
      case !escaping && char === '[':
        pushModifier();
        escaping = true;
        break;
      case escaping && char === ']':
        escaping = false;
        pushArgument();
        break;
      case !escaping && char === ' ':
        if (token && token.trim()) pushToken();
        token += char;
        break;
      case !escaping && char !== ' '
                     && token[token.length - 1] === ' ':
        pushToken();
        token += char;
        break;
      default:
        token += char;
        break;
    }
  }

  return tokens;

  function pushToken() {
    if (!token) return reset();

    if (!token.trim()) {
      tokens.push({ type: EMPTY, token, index });
    } else if (!isNaN(+token)) {
      tokens.push({ type: CONSTANT, token, index });
    } else {
      tokens.push({ type: VARIABLE, token, index, modifiers: [...modifiers] });
    }
    reset(false);
  }

  function pushArgument() {
    const [modifier] = modifiers;
    if (modifier) modifier.argument = token;
    token = '';
  }

  function pushModifier() {
    if (token) modifiers.push({ type: MODIFIER, token, index });
    token = '';
  }

  function pushOperator(symbol: string) {
    tokens.push({ type: OPERATOR, token: symbol, index });
    reset();
  }

  function reset(pushModifiers: boolean = true) {
    if (pushModifiers) tokens = [...tokens, ...modifiers];

    token = '';
    modifiers = [];
  }
}
