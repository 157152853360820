import { useAppContext, login as loginAction } from '../store';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '../api';
import { Session } from '../types';


type Credentials =
  { login: string } &
  ({ password: string, confirmToken?: never } |
   { confirmToken: string, password?: never });

export function useSignIn({ to }: { to?: string } = {}) {
  const [api] = useAPI({ withAccount: false });
  const [_, dispatch] = useAppContext();
  const navigate = useNavigate();

  return async ({ login, password, confirmToken }: Credentials) => {
    try {
      const user = { login, password };
      const session: Session =
        await api.post('/sessions', { user, confirmToken });
      dispatch(loginAction(session));
      if (to) navigate(to);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
