import React, { useState, useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, useNavigate } from 'react-router-dom'
import { Box, Text, Button } from '@just/jui';
import { useAPI } from '../api';
import { Layout, Loading } from '../components';
import { Form, Report, Map } from '../types';
import { NewFormButton } from '../form-builder';
import ReportForm from './ReportForm';
import EmptyReportGuide from './EmptyReportGuide';


interface Props {

}

interface Segment {
  id: number;
  name: string;
  usage: string;
  assets: { id: number }[];
}

export default function EditReport({

}: Props): React.ReactElement<Props> {
  const [api] = useAPI({ load });

  const [forms, setForms] = useState<Form[]>();
  const [report, setReport] = useState<Report>();
  const [segments, setSegments] = useState<Segment[]>();

  const [editingReport, setEditingReport] = useState(false);
  const [toggleNewForm, setToggleNewForm] = useState(0);

  const navigate = useNavigate();

  const segmentUsage = useCallback(({ id }: Segment) => {
    if (!report || !forms) return '';

    if (id === report.pivotId)
        return t`used as pivot for collecting reports`;

    if (id === report.scenarioId)
        return t`used as reporting scenario`;

    const form = forms.find(f => f.segmentId === id);
    if (form) return t`used as extra segmentation for ${form.code} form`;

    return t`not used in this reporting yet`;
  }, [forms, report]);

  const FormListItem = useCallback(({ form }: { form: Form }) =>
    <Box key={form.id}
         cursor="pointer"
         onClick={() => navigate(`forms/${form.code}`)}>
      <Text h3 color="accent-2">{form.code}</Text>
      <Text color="dark-2">{form.name}</Text>
    </Box>,
  [navigate]);

  const SegmentListItem = useCallback(({ segment }: { segment: Segment }) =>
    <Box key={segment.id}
         cursor="pointer"
         onClick={() => navigate(`segments/${segment.id}`)}>
      <Text h4 color="accent-2">{segment.name}</Text>
      <Text color="dark-3">{segmentUsage(segment)}</Text>
    </Box>,
  [navigate, segmentUsage]);

  const hasAssets = (id: number) => {
    console.log(segments, id);
    return (segments?.find(s => s.id === id)?.assets.length || 0) > 0;
  }

  const ReportHeader = useCallback(({ report, forms, onNewForm, onModify }: {
    report: Report,
    forms?: Form[],
    onNewForm: () => void,
    onModify: () => void
  }) =>
    <Box spacing="m" color="dark-2">
      <Box horizontal spacing="m" margin="m">
        <Text h1 color="dark-1">{report.name}</Text>
        <Button onClick={onModify}>
          <Trans>Modify</Trans>
        </Button>
      </Box>
      {
        report.description &&
          <Text block margin="l" maxWidth="s">
            {report.description}
          </Text>
      }
      {
        forms &&
          forms.length
            ? <LinkToCampaigns />
            : <EmptyReportGuide report={report}
                                hasForms={!!forms?.length}
                                hasPivots={hasAssets(report.pivotId)}
                                hasScenarios={hasAssets(report.scenarioId)}
                                onNewForm={focusNewForm} />
       }
    </Box>
  , [segments]);

  if (!report || !forms || !segments)
    return <Layout><Loading /></Layout>;

  return (
    <Layout>
      <Box color="dark-1">
        {
          editingReport
            ? <ReportForm report={report}
                          onSaved={onReportSaved}
                          onCancel={() => setEditingReport(false)} />
            : <ReportHeader report={report}
                            forms={forms}
                            onNewForm={focusNewForm}
                            onModify={() => setEditingReport(true)} />
        }
      </Box>
      <Box>
        <Box horizontal
             width="80"
             alignItems="start"
             spacing="xl">
          <Box flex="1">
            <Box margin="l">
              <Text h3 line><Trans>Forms</Trans></Text>
              <Text small color="dark-3">
                <Trans>click on code to change form structure</Trans>
              </Text>
            </Box>

            <Box spacing="m" margin="l">
              {
                forms
                  ? forms.map(f => <FormListItem key={f.id} form={f} />)
                  : <Loading>
                      <Trans>Loading list of forms...</Trans>
                    </Loading>
              }
            </Box>
            <NewFormButton active={toggleNewForm}
                           forms={forms || []}
                           onCreate={editNewForm}/>
          </Box>

          <Box flex="1">
            <Box margin="l">
              <Text h3 line><Trans>Segments</Trans></Text>
              <Text small color="dark-3">
                <Trans>click on name to edit content or rename</Trans>
              </Text>
            </Box>

            <Box spacing="m">
              {
                segments
                  ? segments.map(s => <SegmentListItem key={s.id}
                                                       segment={s} />)
                  : <Loading>
                      <Trans>Loading list of segments</Trans>
                    </Loading>
              }
            </Box>
          </Box>
        </Box>
        { editingReport && <Box overlay /> }
      </Box>
    </Layout>
  );

  function onReportSaved(report: Report) {
    setReport(report);
    setEditingReport(false);
  }

  function focusNewForm() {
    setToggleNewForm(toggleNewForm + 1);
  }

  function editNewForm(code: string) {
    navigate('forms/new', { state: { code }});
  }

  async function load() {
    try {
      const [report, forms] = await Promise.all([
        api.get<Report>('/'),
        api.get<Form[]>('/forms'),
      ]);

      setReport(report);
      setForms(forms);

      const ids = [
        report.pivotId,
        report.scenarioId,
        ...forms.map(f => f.segmentId).filter(Boolean) as number[]
      ];

      setSegments(await Promise.all(ids.map(id =>
        api.get<Segment>(`../segments/${id}`)
      )));

    } catch (e) { }
  }
}

const LinkToCampaigns = () =>
  <Text block margin="xl">
    <Trans>
      Proceed to
      <Text link>
        <Link to="../campaigns">
          <Trans>reporting campaigns</Trans>
        </Link>
      </Text>
      to view and edit structured data within forms.
    </Trans>
  </Text>;
