import React, { useState } from 'react';
import useSWR from 'swr';
import { Trans, t } from '@lingui/macro';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, Button, Icon } from '@just/jui';
import { NotFound, Layout, Table, Loading } from '../components';
import { FormData, FormColumn, Segment, Asset } from '../types';
import {
  normalizePeriod,
  shiftPeriod,
  periodCode,
  periodLabel,
  format
} from '../utils';
import { useReport, useForm, dataPath, DataQuery } from './hooks';
import ParamsInfo from './ParamsInfo';
import ColumnInfo from './ColumnInfo';
import FormRow from './FormRow';


const CURRENT = 1;
const byCode = (code: string) => (a: Asset) => a.code === code;

export default function FormView(): React.ReactElement {
  const navigate = useNavigate();
  const params = useParams();
  const report = useReport();
  const form = useForm();

  const [columns, setColumns] = useState<FormColumn[]>([
    { period: 'LY' },
    { period: 'C', current: true },
    { period: 'YE' }
  ]);
  const changeColumn = (i: number, column: FormColumn) => {
    const updated = [...columns] as FormColumn[];
    updated[i] = { ...columns[i], ...column };
    setColumns(updated);
  };
  const columnKey = (c: FormColumn, i?: number) =>
    `${i}${c.period}${c.scenario}`;

  const period = normalizePeriod(params.period, report?.period || 'daily');
  const query = (column: FormColumn = {}): DataQuery => ({
    ...params,
    ...column,
    period: report && column.period
              ? shiftPeriod(period, column.period, report.period)
              : period
  } as DataQuery);

  const useColumnData = (idx: number) =>
    useSWR<FormData>(report ? dataPath(query(columns[idx])): null);

  const { data: dataA } = useColumnData(0);
  const { data: dataB } = useColumnData(1);
  const { data: dataC } = useColumnData(2);

  const data = [dataA, dataB, dataC];
  const { data: checks } = useSWR<FormData[]>(
    report ? dataPath(query(columns[CURRENT])) + '/checks' : null
  );

  if (!report || !form) return <Loading />

  const pivot = report.pivot.assets.find(byCode(params.pivot));
  if (!pivot) return <NotFound />;

  const segment = report.segment;
  const isSegmented = !!form.segmentId;
  const segmentAsset = segment?.assets.find(a => a.code === params.segment);
  if (segment && !segmentAsset) return <NotFound />;

  const label = (period: Date) => periodLabel(period, report.period);
  const value = (column: number, row: string) =>
    data[column]?.[row] as number || 0;

  const formatValue = (column: number, row: string) => {
    const rows = data[column];
    return rows ? format(rows[row] as number || 0) : t`—`;
  }

  const back = () => navigate(isSegmented ? '../../../' : '../../');
  const modify = () => navigate('input');

  const checkResults = checks?.[0] || {} as FormData;
  const hasWarning = (code: string) => !!checkResults[code];

  const expected = (code: string) =>
    value(CURRENT, code) + (checkResults[code] as number || 0);

  const goToSiblingPeriod = (mod: number) =>
    navigate([
      '/', params.account,
      'reports', params.report,
      'campaigns',
      params.scenario,
      periodCode(shiftPeriod(period, mod, report.period)),
      params.form,
      params.pivot,
      form.segmentId ? params.segment : false
    ].filter(Boolean).join('/'));

  const prevPeriod = () => goToSiblingPeriod(-1);
  const nextPeriod = () => goToSiblingPeriod(1);

  return (
    <Layout context={<ParamsInfo report={report} />}
            buttons={<>
              <Button primary icon="arrow_back" onClick={back}>
                <Trans>Back</Trans>
              </Button>
              {
                !pivot.isConsolidator &&
                  <Button icon="edit" onClick={modify}>
                    <Trans>Modify</Trans>
                  </Button>
              }
            </>}>
      <Table.Header>
        <Box width="10">
          <Text block strong>{form.code}</Text>
        </Box>
        <Box width="35" spacing="s">
          <Text block strong caps>{form.name}</Text>
          <Text block color="dark-2" small>
            <Text strong>{pivot.code}</Text>
            &nbsp; - {pivot.name}
          </Text>
          {
            segmentAsset &&
              <Text block color="dark-2" small>
                <Text strong>{segmentAsset.code}</Text>
                &nbsp; - {segmentAsset.name}
              </Text>
          }
        </Box>
        <Text width="3" right>
          <Icon color="dark-3"
                cursor="pointer"
                title={t`Previous period`}
                onClick={prevPeriod}>arrow_backward</Icon>
        </Text>
        {
          columns.map((column, i) =>
            <ColumnInfo key={i}
                        report={report}
                        column={column}
                        onChange={column => changeColumn(i, column)} />
          )
        }
        <Text width="3" right>
          <Icon color="dark-3"
                cursor="pointer"
                title={t`Next period`}
                onClick={nextPeriod}>arrow_forward</Icon>
        </Text>
      </Table.Header>

      <Table.Content>
        {
          form.rows.map(row =>
            <FormRow key={row.code}
                     row={row}
                     warning={
                       hasWarning(row.code) &&
                         <Text small strong block error>
                           <Trans>Not equal to {row.validator}</Trans>
                         </Text>
                     }>
             {columns.map((column, i)  =>
                 column.current
                   ? <Box key={columnKey(column, i)} width="15" spacing="xs">
                       <Text block right>
                         {formatValue(i, row.code)}
                       </Text>
                       {
                         hasWarning(row.code) &&
                           <Text block right error>
                             &ne; {format(expected(row.code))}
                           </Text>
                       }
                     </Box>
                   : <Text key={i} block width="15" right color="dark-3">
                       {formatValue(i, row.code)}
                     </Text>
               )
             }
             <Box width="3" />
           </FormRow>
          )
        }
      </Table.Content>
    </Layout>
  );
}
