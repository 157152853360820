import React from 'react';;
import { Navigate, useParams } from 'react-router-dom';
import { Loading } from '../components';
import { periodCode, normalizePeriod } from '../utils';
import { useReport } from './hooks';



export default function Current(): React.ReactElement {
  const params = useParams();
  const report = useReport();

  if (!report) return <Loading />;

  if (!report.forms.length ||
      !report.scenario.assets.length ||
      !report.pivot.assets.length)
    return <Navigate to={`/${[
      params.account,
      'reports', params.report,
      'settings'
    ].join('/')}`} />

  const to = report.scenario.assets[0].code + '/' +
             periodCode(normalizePeriod(new Date(), report.period)!);

  return <Navigate to={to} replace />
}
