import React, { useState, useEffect } from 'react';
import { TextInput } from '@just/jui';
import { format, parseDecimal } from '../utils';


interface Props {
  id?: string;
  value: number;
  onBlur?: () => void;
  onChange?: (value: number) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  autoFocus?: boolean;
}

export default function NumericInput({
  value,
  onBlur,
  onChange,
  ...inputProps
}: Props): React.ReactElement<Props> {
  const initialInput = () => format(value)
  const [input, setInput] = useState(initialInput);
  useEffect(() => setInput(initialInput()), [value]);

  return (
    <TextInput outline="s"
               value={input}
               onBlur={parseAndNotify}
               onChange={setInput}
               {...inputProps} />
  );

  function parseAndNotify() {
    const number = parseDecimal(input);

    if (isNaN(number)) setInput(initialInput());
    else if (number !== value) onChange?.(number);

    onBlur?.()
  }
}
