import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Trans, t } from '@lingui/macro';
import { Box, Text, Field, Select, Fieldset } from '@just/jui';
import { useAPI } from '../api';
import { Group, AccessList } from '../types';


interface Props {
  groups?: Group[];
  accessList: AccessList;
  onChange?: (acl: AccessList) => void;
}


export default function AccessListEditor({
  accessList,
  groups: initialGroups,
  onChange,
}: Props): React.ReactElement<Props> {
  const { account } = useParams();

  const [api, loading] = useAPI({ withReport: false });
  const [filter, setFilter] = useState('');

  const { data: groups, mutate } =
    useSWR<Group[]>(`/accounts/${account}/groups`);

  const visibleGroups = useMemo(() => filter && groups
    ? groups.filter(({ name }) => name.startsWith(filter))
    : groups || []
  , [groups, filter]);

  const SHORTCUTS = [t`NO`, t`R`, t`RW`];

  const ACCESS = {
    0: t`NO ACCESS`,
    1: t`READ`,
    2: t`WRITE`
  };

  // const [first, ...others] = new Set(
  //   visibleGroups.reduce((modes, { id }) => {
  //     if (id in accessList) modes.push(accessList[id]);
  //     return modes;
  //   }, [] as (keyof typeof ACCESS)[])
  // );
  // const consensus = others.length > 0 ? null : first;

  return (
    <Fieldset label={t`Access list`} spacing="m">
      <Box width="33" horizontal justify="start" padding="0 m 0 0">
        <Field value={filter}
               icon="search"
               onChange={setFilter}
               placeholder={t`Find or create a group`}
               suffix={
                <Select value={t`set all`}
                        small
                        options={ACCESS}
                        onChange={changeAll} />
               }/>
      </Box>
      <Box horizontal justify="start" wrap>
        {
          filter && !visibleGroups.length &&
            <Box spacing="s" padding="m">
              <Text strong block><Trans>No groups found</Trans></Text>
              <Text block>
                <Trans>
                  <Text link onClick={createNewGroup}>
                    <Trans>Create a new group "{filter}"</Trans>
                  </Text>
                  <Trans>&nbsp;or&nbsp;</Trans>
                  <Text link onClick={clearFilter}>
                    <Trans>clear filter</Trans>
                  </Text>
                </Trans>
              </Text>
            </Box>
        }
        {
          visibleGroups.map(group =>
            <Box horizontal
                 padding="xs m"
                 width="33"
                 key={group.id}>
              <Text color="dark-2" flex="1">{group.name}</Text>
              <Select width="10"
                      small
                      value={accessFor(group.id)}
                      options={ACCESS}
                      onChange={v => change(group.id, v)} />
              <Box width="10" />
            </Box>
          )
        }
      </Box>
   </Fieldset>
  );

  function sortBy(a: Group, b: Group) {
    const aAccess = (accessList[a.id] || 0) > 0;
    const bAccess = (accessList[a.id] || 0) > 0;

    switch (true) {
      case aAccess && !bAccess: return 1;
      case bAccess && !aAccess: return -1;
      case a.name > b.name: return 1;
      default: return -1;
    }
  }

  async function createNewGroup() {
    try {
      const group: Group =
        await api.post('/groups', { group: { name: filter }});

      mutate((groups || []).concat(group));
    }
    catch (e) { console.error(e) }
  }

  function clearFilter() {
    setFilter('');
  }

  function change(groupId: number, v: string) {
    if (!onChange) return;

    const { [groupId]: removed, ...without } = accessList;
    const mode = parseInt(v) as keyof typeof ACCESS;

    onChange(mode === 0 ? without : { ...without, [groupId]: mode });
  }

  function changeAll(v: string) {
    if (!onChange) return;

    const mode = parseInt(v) as keyof typeof ACCESS;
    const ids = visibleGroups.map(g => g.id);

    if (!groups) return;

    onChange(groups.reduce((result, { id }) => {
      const updated = ids.includes(id);
      const hadAccess = accessList[id] > 0;

      if (updated && mode > 0) result[id] = mode;
      else if (!updated && hadAccess) result[id] = accessList[id];

      return result;
    }, {} as typeof accessList));
  }

  function accessFor(groupId: number) {
    return SHORTCUTS[accessList[groupId] || 0];
  }
}
