import React from 'react';
import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import UserForm from './UserForm';


export default function Signup(): React.ReactElement {
  const navigate = useNavigate();

  return (
    <UserForm title={t`Astring Reporting — New User`}
              onSave={proceedToSignin} />
  );

  function proceedToSignin() {
    navigate('/signin');
  }
}
