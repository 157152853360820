import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Map } from './types';
import { useAppContext } from './store';

export const None = Object.freeze({});
export type FieldErrors = Map|null;
type Method = 'get'|'put'|'post'|'patch'|'delete';

interface APIResponse<T = any> {
  status: number;
  error?: { message: string, details?: Map };
  result?: T;
}

export class APIError extends Error {
  details?: Map;

  constructor(err: string|APIResponse) {
    super(typeof err === 'string' ? err : err.error!.message);
    if (typeof err !== 'string') this.details = err.error!.details;
  }

  get errorsList(): string[] {
    const { details } = this;
    return details ? Object.values(details) : [this.message];
  }

  toString() {
    return this.details
      ? `API Errors: ${Object.values(this.details).join('\n')}`
      : super.toString();
  }
}

export function useAPI(
  {
    setErrors, setFieldErrors, setInvalid, silient, load,
    withAccount = true,
    withReport = true
  }: {
    setErrors?: (e: string[]) => void,
    setFieldErrors?: (e: Map|null) => void,
    setInvalid?: (value: boolean) => void,
    silient?: boolean,
    load?: () => Promise<void>,
    withAccount?: boolean,
    withReport?: boolean
  } = {}
) {
  const noErrors: string[] = [];

  const token = useAppContext(s => s.session?.token);
  const [loading, setLoading] = useState(!!load);
  let { account, report } = useParams();

  useEffect(() => { load?.() }, []);

  const request = (method: Method) =>
    apiRequest(method, {
      token,
      silient,
      processUrl: resourceURL({
        account: withAccount || withReport ? account : null,
        report: withReport ? report : null
      }),
      onBefore: () => {
        setLoading(true);
        setErrors?.(noErrors);
        setInvalid?.(false);
        setFieldErrors?.(null);
      },
      onError: (e) => {
        setErrors?.(e.errorsList);
        setInvalid?.(true);
        setFieldErrors?.(e.details || null);
      },
      onAfter: () => setLoading(false)
    });

  const api = {
    get: request('get'),
    put: request('put'),
    post: request('post'),
    patch: request('patch'),
    delete: request('delete'),
  };

  return [api, loading] as const;
}

export const apiRequest = (
  method: Method,
  { token, silient, processUrl, onBefore, onError, onAfter }: {
    token?: string,
    silient?: boolean,
    processUrl?: (url: string) => string,
    onBefore?: () => void,
    onError?: (e: APIError) => void,
    onAfter?: () => void
  }
) =>
  async <T>(url: string, payload?: any): Promise<T> => {
    onBefore?.();

    url = processUrl ? processUrl(url) : url;
    if (url.startsWith('/')) url = url.slice(1);

    try {
      const response = await axios.request<APIResponse<T>>({
        method,
        url: `/api/${url}`,
        data: payload,
        headers: token && { Authorization: `Bearer ${token}` }
      });

      if (response.status !== 200 || response.data.status !== 200)
        throw new APIError(response.data);

      return response.data.result as T;
    } catch (e) {
      const error = e instanceof APIError ? e : new APIError(e.message);
      onError?.(error);

      if (silient) return None as T;
      throw error;
    } finally {
      onAfter?.();
    }
  };

export const resourceURL = ({ account, report }: {
  account?: string|null,
  report?: string|null
}) =>
  (url: string) => {
    let depth = 0;
    while (url.startsWith('../')) {
      url = url.slice(3);
      depth++;
    }

    if (url.startsWith('/')) url = url.slice(1);

    if (report && (--depth < 0))
      url = `reports/${report}/${url}`;

    if (account && (--depth < 0))
      url = `accounts/${account}/${url}`;

    return url;
  };
