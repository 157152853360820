import { useMemo } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { Params } from 'react-router';
import { resourceURL } from '../api';
import { periodCode } from '../utils';
import { Form, FormData, Segment, Report as ReportAttrs } from '../types';


export interface DataQuery {
  account: string,
  report: string,
  form: string,
  scenario: string|number,
  period: string|Date,
  pivot?: string|number,
  segment?: string|number
}

export function dataPath(query: DataQuery): string {
  let path = `/accounts/${query.account}` +
             `/reports/${query.report}` +
             `/forms/${query.form}` +
             `/data/${query.scenario}` +
             `/periods/${periodCode(query.period)}`;

  if (query.pivot) path += `/pivots/${query.pivot}`;
  if (query.segment) path += `/segments/${query.segment}`;

  return path;
}

export function useData(
  query: Partial<DataQuery> = {}
): [FormData|undefined, Error|undefined] {
  const params = useParams();
  const { data, error } = useSWR<FormData>(
    dataPath({ ...params, ...query } as DataQuery)
  );
  return [data, error];
}

export function useForm() {
  const params = useParams();
  const url = resourceURL(params);

  const { data: form } = useSWR<Form>(url(`/forms/${params.form}`));
  return form;
}

export function useReport() {
  const params = useParams();
  const url = resourceURL(params);

  const { data: report } = useSWR<ReportAttrs>(url(''));
  const { data: forms } = useSWR<Form[]>(url('forms'));

  const { data: pivot } =
    useSWR<Segment>(() => url(`../segments/${report!.pivotId}`));

  const { data: scenario } =
    useSWR<Segment>(() => url(`../segments/${report!.scenarioId}`));

  const form = params.form ? forms?.find(f => f.code === params.form)
                           : undefined;

  const { data: segment } =
    useSWR<Segment>(() => form?.segmentId
        ? url(`../segments/${form.segmentId}`)
        : null);

  return useMemo(() =>
    !(report && forms && pivot && scenario)
      ? undefined
      : {
          id: report.id,
          code: report.code,
          name: report.name,
          period: report.period,
          description: report.description,

          form,
          forms,
          pivot,
          segment,
          scenario,
        },
    [report, forms, pivot, scenario, form, segment]
  );
}

export type Report = NonNullable<ReturnType<typeof useReport>>;

// forms/isn/data/:datumId/periods/:periodId/
//      /pivots/:pivotId/segments/:segmentId/
