import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { useAPI, FieldErrors } from '../api';
import { useAppContext, login } from '../store';
import {
  Box,
  Text,
  Field,
  Button,
  Fieldset,
  validators as v
} from '@just/jui';
import { Frame } from '../components';


interface Props {
  id?: string;
  token?: string|null;

  name?: boolean;
  email?: boolean;
  password?: boolean;

  title: string;
  button?: string;
  description?: string;
  onSave: (user: UserInfo, password?: string) => void;
}

export interface UserInfo {
  id: string;
  name?: string;
}

interface UserForm {
  id?: string;
  name?: string|null;
  password?: string;
  passwordConfirm?: string;
}

export default function Signin({
  title,
  button,
  description,
  onSave,
  id,
  token,
  name = true,
  email = true,
  password = true
}: Props): React.ReactElement<Props> {
  const [user, setUser] = useState<UserForm>({});

  const [errors, setFieldErrors] = useState<FieldErrors>();
  const [api, loading] = useAPI({ setFieldErrors });

  return (
    <Frame title={title} onSubmit={save}>
      <Box padding="0 0 m 0" spacing="s">
        {
          description ||
            <Text block>
              <Trans>Please provide some information about yourself.</Trans>
            </Text>
        }
      </Box>

      {!id &&
        <Field label={t`E-Mail`}
               placeholder={t`Your e-mail address`}
               required
               errors={errors?.id}
               value={user.id}
               validate={[v.format(/^\S+@\S+$/i, t`email`)]}
               onChange={id => change({ id })} />
      }

      {name &&
        <Field label={t`Name`}
               placeholder={t`Your first and last name`}
               value={user.name}
               errors={errors?.name}
               validate={v.length(0, 100)}
               onChange={name => change({ name })} />
      }

      {password &&
        <>
          <Field
            label={t`Password`}
            masked
            placeholder={t`At least 8 letters and contain different symbols`}
            required
            errors={errors?.password}
            validate={[v.password({ lower: 2 })]}
            value={user.password}
            onChange={password => change({ password })} />

          <Field
            label={t`Confirm password`}
            masked
            placeholder={t`The same as the password`}
            required
            validate={[v.match(user.password, t`not same as password`)]}
            value={user.passwordConfirm}
            onChange={passwordConfirm => change({ passwordConfirm })} />
        </>
      }

      <Box horizontal justify="center" padding="m 0 0 0">
        { loading
            ? <Text><Trans>Saving...</Trans></Text>
            : <Button primary submit>
                {button || <Trans>Save</Trans>}
              </Button>
        }
      </Box>
    </Frame>
  );

  function change(input: Partial<UserForm>) {
    setUser({ ...user, ...input });
    setFieldErrors(null);
  }

  function isFilled() {
    return (
      (id || (!id && user.id)) &&
      (!password || (password && user.password && user.passwordConfirm))
    );
  }

  async function save() {
    try {
      if (!isFilled()) return setFieldErrors({
        id: t`is required`,
        password: t`is required`,
        passwordConfirm: t`is required`
      });

      if (user.password !== user.passwordConfirm) {
        return setFieldErrors({
          passwordConfirm: t`Password and confirmation are not the same`
        });
      }

      const saved: { name?: string|null, id: string } = id
        ? await api.patch(`/users/${id}`, { user, token })
        : await api.post('/users', { user });

      onSave({ name: saved.name!, id: (id || saved.id)! }, user.password);
    } catch (e) { /* setErrors handled automatically */ }
  }
}
