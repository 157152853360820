import React from 'react';
import { Box, Text } from '@just/jui';
import { Table } from '../components';
import { Asset } from '../types';
import { t } from '@lingui/macro';


interface Props {
  asset: Asset;
  onClick?: (asset: Asset) => void;
}

export default function AssetRow({
  asset,
  onClick
}: Props): React.ReactElement<Props> {
  return (
    <Table.Row onClick={onClick ? () => onClick(asset) : undefined}>
      <Text block width="10"small strong color="dark-2">
        {asset.code}
      </Text>
      <Text block width="40">{asset.name}</Text>
      <Text block width="10" color="dark-2" small caps>
        {asset.isValidatable ? t`Yes` : t`No`}
      </Text>
      <Text block color="dark-2" width="30">{asset.group}</Text>
      <Box flex="1"></Box>
    </Table.Row>
  );
}
