import React, { useMemo, useCallback } from 'react';
import {
  Box,
  Text,
  List,
  Named,
  Items,
  InferID,
  InferItem,
  ItemProps,
  BoxItemProps,
  normalizeItems,
  useListContext
} from '@just/jui';


interface SelectProps<T> {
  options: T;
  onChange?: (id: InferID<T>, item: InferItem<T>) => void;
  selected?: InferID<T>;
  withCode?: boolean;
}

interface GroupedSelectProps<T> extends SelectProps<T> {
  groupBy: (item: InferItem<T>) => string,
}

interface Option<ID = any> {
  id: ID;
  name?: string;
  code?: string;
}

const NameAndCode = createListItem({}, ({ name, code, id }) =>
  <Text block>
          {code &&
            <>
              <Text strong>{code}</Text>
              &nbsp;&mdash;&nbsp;
            </>
          }
          {name || id}
  </Text>
);

const NameOnly = createListItem({}, ({ id, name }) =>
  <Text block>{name || id}</Text>
);

export function createListItem<T extends Option = Option>(
  options: { padding?: BoxItemProps['padding'] },
  render: (item: T) => React.ReactNode,
) {
  return React.memo((props: ItemProps<T>) => {
    const { item, active, selected } = props;
    const { getItemProps: bindToList } = useListContext<T>();

    return (
      <Box as="li"
           cursor="pointer"
           {...bindToList(item)}>
        <Text as="div"
              color={selected ? "black-1" : active ? "dark-1" : "dark-2"}
              underline={selected}
              strong={selected}>
              {render(item)}
        </Text>
      </Box>
    );
  });
}

export function Select<T extends Items>({
  options,
  onChange,
  selected,
  withCode,
}: SelectProps<T>): React.ReactElement<SelectProps<T>> {
  return <List tabIndex={1}
               items={options}
               item={withCode ? NameAndCode : NameOnly}
               spacing="m"
               selection={selected ? [selected] : []}
               onCommit={item => onChange?.(item.id, item)}
               horizontal={!withCode} />
}

export function GroupedSelect<T extends Items>({
  options,
  groupBy,
  onChange,
  selected,
}: GroupedSelectProps<T>): React.ReactElement<SelectProps<T>> {
  type Groups = { [group: string]: InferItem<T>[] };

  const groups: Groups = useMemo(() =>
    groupBy
      ? normalizeItems(options).reduce((groups, untypedItem) => {
        const item = untypedItem as InferItem<T>;
        const groupKey = groupBy(item);
        const group = groups[groupKey] = groups[groupKey] || [];
        group.push(item);

        return groups;
      }, {} as Groups)
      : { '': normalizeItems(options) as InferItem<T>[] }
    , [options, groupBy]);

  return (
    <List tabIndex={1}
          items={options}
          spacing="l"
          multiColumn
          onCommit={item => onChange?.(item.id, item)}
          selection={selected ? [selected] : []}>
      {
        (_, { active, selection }) => Object.entries(groups).map(
          ([group, items]) =>
            <Box spacing="m" key={group}>
              {group && <Text block line>{group}</Text>}
              <Box columns={2} key={group}>
                {items.map(item =>
                  <NameAndCode key={item.id}
                               item={item}
                               active={active === item.id}
                               selected={selection.includes(item.id)} />
                )}
              </Box>
            </Box>
        )

      }
    </List>
  );
}
