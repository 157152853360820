import React, { useState, useEffect } from 'react';
import { Box, Field, Button, validators as v } from '@just/jui';
import { Trans, t } from '@lingui/macro';
import { Table } from '../components';


interface Props {
  forms: { code: string }[];
  active?: boolean|number;
  onCreate?: (code: string) => void;
}

export default function NewFormTile({
  forms,
  active: activeProp,
  onCreate
}: Props): React.ReactElement<Props> {
  const [active, setActive] = useState(!!activeProp);
  const [code, setCode] = useState('');

  useEffect(() => setActive(!!activeProp), [activeProp]);

  const usedCodes = forms.map(f => f.code);
  const codePrompt = () =>
    <Table.Form>
      <Box padding="s m s m" spacing="l">
        <Field label={t`New form code`}
               value={code}
               focus={code === ''}
               convert="uppercase"
               placeholder={t`Type 3 letter unique code`}
               validate={[v.unique(usedCodes),
                          v.format(/^[A-Z]{3}$/g, t`not 3 letters`)]}
               onChange={setCode} />

        <Box horizontal>
          <Button icon="clear" onMouseDown={cancel}>
            <Trans>Cancel</Trans>
          </Button>
          <Button icon="done"
                  primary
                  disabled={code === ''}
                  onClick={create}>
            <Trans>Create</Trans>
          </Button>
        </Box>
      </Box>
    </Table.Form>

  return active
    ? codePrompt()
    : <Button icon="playlist_add"
              onClick={askForCode}>
        <Trans>Create a new form</Trans>
      </Button>

  function askForCode() {
    setActive(true);
  }

  function cancel(e: React.MouseEvent) {
    setActive(false);
    e.stopPropagation();
  }

  function create() {
    if (!code) return;
    onCreate?.(code);
  }
}
