import React, { useState, useEffect } from 'react';
import { Trans, t } from '@lingui/macro';
import {
  Box,
  Text,
  Button,
  Field,
  Checkbox
} from '@just/jui';
import { Table } from '../components';
import { Asset, Segment } from '../types';
import { useAPI } from '../api';


interface Props {
  segment: Segment;
  onSave?: (segment: Segment) => void;
  onCancel?: () => void;
}

export default function SegmentForm({
  segment: initialSegment,
  onSave,
  onCancel
}: Props): React.ReactElement<Props> {
  const { assets, ...onlySegment } = initialSegment;

  const [api] = useAPI({ withReport: false });
  const [segment, setSegment] = useState(onlySegment);

  const url = `/segments/${segment.id}`;
  const consolidator = segment.consolidatorId &&
    assets.find(a => a.id === segment.consolidatorId);

  return (
    <Table.Form
      borderCollapse={false}
      buttons={<>
        <Button icon="clear" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button icon="done" onClick={save} primary>
          <Trans>Save</Trans>
        </Button>
      </>}>
      <Box padding="s"
           spacing="l">
        <Box horizontal
             width="80"
             justify="start"
             alignItems="start"
             spacing="m">

          <Field label={t`Code`}
                 width="10"
                 required
                 value={segment.codeFormat}
                 onChange={codeFormat => change({ codeFormat })} />

          <Field label={t`Name`}
                 width="40"
                 required
                 value={segment.name}
                 onChange={name => change({ name })} />

          <Field label={t`Consolidate into`}
                 width="40"
                 from={assets}
                 placeholder={t`Do not consolidate`}
                 value={consolidator ? consolidator.name : ''}
                 onChange={(asset: Asset) =>
                   change({ consolidatorId: asset?.id })} />
        </Box>
      </Box>
    </Table.Form>
  );

  async function save() {
    onSave?.({
      ...segment,
      assets: assets.map(a =>
        ({ ...a, isConsolidator: segment.consolidatorId === a.id }))
    });
  }

  function change(attrs: Partial<Segment>) {
    setSegment({ ...segment, ...attrs });
  }
}
