import React, { useState } from 'react';
import { Box, Text, Field, Button, validators as v  } from '@just/jui';
import { Trans, t } from '@lingui/macro';
import { Report, ReportPeriod } from '../types';
import { useAPI, FieldErrors } from '../api';
import { periodNames } from '../utils';
import { Table } from '../components';


interface Props {
  report: Report
  onSaved: (r: Report) => void;
  onCancel: () => void;
}

export default function ReportForm({
  report: initialReport,
  onSaved,
  onCancel
}: Props): React.ReactElement<Props> {
  const [report, setReport] = useState(initialReport);
  const [errors, setFieldErrors] = useState<FieldErrors>(null);
  const [api] = useAPI({ setFieldErrors });

  const PERIODS = periodNames();

  return (
    <Box margin="xl">
      <Table.Form borderCollapse={false}
                  buttons={<>
                    <Button icon="clear" onClick={onCancel}>
                      <Trans>Cancel</Trans>
                    </Button>
                    <Button icon="done" primary onClick={save}>
                      <Trans>Save</Trans>
                    </Button>
                  </>}>
        <Box spacing="l" padding="m">
          <Box horizontal justify="start" spacing="m" alignItems="start">
            <Field label={t`Code`} width="10"
                   placeholder={t`Ex: IFRS`}
                   value={report.code}
                   required
                   convert="lowercase"
                   errors={errors?.code}
                   validate={[v.slug, v.length(1, 15)]}
                   onChange={code => change({ code })} />
            <Field label={t`Name`} flex="1"
                   placeholder={t`Ex: Management reporting`}
                   value={report.name}
                   required
                   errors={errors?.name}
                   validate={[v.length(4, 100)]}
                   onChange={name => change({ name })} />
            <Field label={t`Reporting period`} width="20"
                   value={PERIODS[report.period]}
                   required
                   errors={errors?.period}
                   from={PERIODS}
                   onChange={(period: ReportPeriod) => change({ period })} />
          </Box>
          <Field label={t`Description`}
                 value={report.description}
                 validate={[v.length(0, 250)]}
                 onChange={description => change({ description })} />
        </Box>
      </Table.Form>
    </Box>
  );

  function change(attrs: Partial<Report>) {
    setReport({ ...report, ...attrs });
  }

  async function save() {
    try {
      await api.patch('', { report });
      onSaved(report);
    } catch (e) { }
  }
}
