import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Box, Text, Dropdown } from '@just/jui';
import { FormColumn } from '../types';
import { shiftPeriod, periodLabel, normalizePeriod, changed } from '../utils';
import { Report } from './hooks';
import ColumnSettings from './ColumnSettings';


interface Props {
  uom?: string;
  report: Report;
  column: FormColumn;
  onChange?: (column: FormColumn) => void;
}


export default function ColumnInfo({
  uom,
  report,
  column,
  onChange
}: Props): React.ReactElement<Props> {
  const { period: periodType } = report;
  const params = useParams();
  const [changes, setChanges] = useState<FormColumn|null>(null);

  const period = shiftPeriod(
    normalizePeriod(params.period, periodType),
    column.period,
    periodType
  );

  const scenario = report.scenario.assets.find(
      a => a.code === (column.scenario || params.scenario)
    )?.name || params.scenario;

  const applyChanges = () => {
    if (changed(column, changes)) onChange!({ ...column, ...changes });
    setChanges(null);
  };

  const configure = () => setChanges(column);
  const editable = onChange && !column.current;

  return (
    <Box width="15"
         color="dark-2"
         cursor={editable ? 'pointer' : undefined}
         onMouseDown={editable ? configure : undefined}>
      <Text as="div"
            color={column.current && "dark-1"}
            strong={column.current}
            small
            right>
        <Text block>{periodLabel(period, periodType)}</Text>
        <Text block>
          {uom || <Trans>kUSD</Trans>},&nbsp;
          {scenario}
        </Text>
      </Text>

      {editable &&
        <Dropdown visible={!!changes}
                  onBlur={applyChanges}
                  delay={1}>
          <ColumnSettings report={report}
                          column={changes || column}
                          onChange={setChanges} />
        </Dropdown>
      }
    </Box>
  );
}
