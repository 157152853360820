import React, { useState, useMemo, useEffect } from 'react';
import { Trans, t, Plural } from '@lingui/macro';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, Button } from '@just/jui';
import { useAPI } from '../api';
import { Layout, Loading, Table } from '../components';
import { Segment, Asset, Group } from '../types';
import AssetRow from './AssetRow';
import AssetForm from './AssetForm';
import SegmentForm from './SegmentForm';


interface Props {
  segment: Segment;
  onDone?: () => void;
}

export default function SegmentEditor({
  segment: initialSegment,
  onDone
}: Props): React.ReactElement<Props> {
  const [api] = useAPI({ withReport: false });
  const [segment, setSegment] = useState<Segment>(initialSegment);

  const [modifingSegment, setModifingSegment] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset|null>(null);

  const navigate = useNavigate();

  const assets = useMemo(() =>
    (segment.assets || []).sort((a, b) => a.order - b.order), [segment]);

  const consolidator = segment.assets.find(a => a.isConsolidator);

  return (
    <Layout buttons={<>
              <Button icon="arrow_back" primary onClick={back}>
                <Trans>Back</Trans>
              </Button>
              {
                !modifingSegment &&
                  <Button icon="playlist_add" onClick={newAsset}>
                    <Trans>New Value</Trans>
                  </Button>
              }
            </>}>
      {modifingSegment
        ? <SegmentForm segment={segment}
                       onSave={saveSegment}
                       onCancel={hideSegmentEditor} />
        :
      <Table.Header>
        <Box width="10">
          <Text h4 caps strong width="10"><Trans>Code</Trans></Text>
          <Text strong color="dark-2" small>
            <Plural value={segment.codeFormat.length}
                    one="# letter"
                    other="# letters" />
          </Text>
        </Box>
        <Box width="40">
          <Text h4 caps strong>
            <Trans>"{segment.name}" values</Trans>
          </Text>
          <Text color="dark-2" small>
            {
              !consolidator
                ? t`Not consolidated`
                : <Text>
                    <Trans>
                      Consolidated into&nbsp;
                      <Text strong caps>{consolidator.code}</Text>
                    </Trans>
                  </Text>
            }
          </Text>
        </Box>
        <Text h4 caps width="10"><Trans>Cross-checks?</Trans></Text>
        <Text h4 caps><Trans>Grouping</Trans></Text>
        <Box flex="1"></Box>
        <Box>
          <Button onClick={modifySegment}>
            <Trans>Modify</Trans>
          </Button>
        </Box>
      </Table.Header>
      }

      <Table.Content>
        {
          selectedAsset && !selectedAsset.id &&
            <AssetForm segment={segment}
                       asset={selectedAsset}
                       onSave={createAsset}
                       onCancel={hideAssetEditor} />
        }
        {
          assets.map(asset =>
            selectedAsset && selectedAsset.id === asset.id
              ? <AssetForm key={asset.id}
                           segment={segment}
                           asset={selectedAsset}
                           onSave={saveAsset}
                           onCancel={hideAssetEditor} />
              : <AssetRow key={asset.id}
                          asset={asset}
                          onClick={select}/>
          )
        }
        {
          !segment.assets.length && !selectedAsset &&
            <Box padding="l">
              <Text block center as="div">
                <Text large block strong padding="m">
                  <Trans>
                    There are no values of the segment defined yet.
                  </Trans>
                </Text>
                <Text color="dark-4" link onClick={newAsset}>
                  <Trans>
                    Click here or press the "NEW VALUE" button
                    on the sidebar to create a first one.
                  </Trans>
                </Text>
              </Text>
            </Box>
        }
        { (selectedAsset || modifingSegment) && <Box overlay /> }
      </Table.Content>
    </Layout>
  );

  function back() {
    navigate('../..');
  }

  function createAsset(asset: Asset) {
    if (!segment) return;

    setSegment({
      ...segment,
      assets: [...segment.assets, asset]
    });
    setSelectedAsset(null);
  }

  function saveAsset(asset: Asset) {
    if (!segment) return;

    setSegment({
      ...segment,
      assets: segment.assets.map(a => a.id === asset.id ? asset : a)
    });
    setSelectedAsset(null);
  }

  async function saveSegment(segment: Segment) {
    await api.patch(`/segments/${segment.id}`, { segment });
    setSegment(segment);
    setModifingSegment(false);
  }

  function modifySegment() {
    setModifingSegment(true);
    setSelectedAsset(null);
  }

  function hideSegmentEditor() {
    setModifingSegment(false);
  }

  function hideAssetEditor() {
    setSelectedAsset(null);
  }

  function select(asset: Asset) {
    setSelectedAsset(asset);
    setModifingSegment(false);
  }

  function newAsset() {
    const newAsset: Asset = {
      name: '',
      code: '',
      group: '',
      order: 0,
      isValidatable: true,
      isConsolidator: false
    } as Asset;
    setSelectedAsset(newAsset);
  }
}
