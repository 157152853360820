import React, { useMemo } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { Box, Text, Icon } from '@just/jui';
import { Layout, Loading, NotFound, Table } from '../components';
import {
  normalizePeriod,
  shiftPeriod,
  periodLabel,
  periodCode,
} from '../utils';
import { useReport, dataPath, DataQuery } from './hooks';
import { group } from '../utils';
import FormStatus from './FormStatus';
import ParamsInfo from './ParamsInfo';


const MAX_FORMS = 7;

export default function CampaignView(): React.ReactElement {
  const report = useReport()
  const params = useParams();
  const navigate = useNavigate();

  const groupedPivots = useMemo(() => group(report?.pivot.assets), [report]);

  if (!report) return <Layout><Loading /></Layout>;
  if (!report.forms.length)
    return <Navigate to={`/${[
      params.account,
      'reports', params.report,
      'settings'
    ].join('/')}`} />

  const visibleForms = report.forms.slice(0, MAX_FORMS);
  const hiddenForms = report.forms.slice(MAX_FORMS);

  const scenario =
    report.scenario.assets.find(a => a.code === params.scenario);
  const period = normalizePeriod(params.period, report.period);

  if (!scenario || !period)
    return <NotFound />

  const formDataPath = (form: string) =>
    dataPath({ ...params, form, period, scenario: scenario.id! } as DataQuery);

  const goToSiblingPeriod = (mod: number) =>
    navigate(`../${periodCode(shiftPeriod(period, mod, report.period))}`);

  const prevPeriod = () => goToSiblingPeriod(-1);
  const nextPeriod = () => goToSiblingPeriod(1);

  return (
    <Layout context={<ParamsInfo report={report} />}>
      <Table.Header>
        <Box horizontal width="40" justify="start" spacing="m">
          <Icon color="dark-3"
                cursor="pointer"
                title={t`Previous period`}
                onClick={prevPeriod}>arrow_back</Icon>
          <Box spacing="s">
            <Text block strong caps>{report.name}</Text>
            <Text block color="dark-2" small>
              {periodLabel(period, report.period)}&nbsp;
              {scenario.name}, <Trans>Unlocked</Trans>
            </Text>
          </Box>
          <Icon color="dark-3"
                cursor="pointer"
                title={t`Next period`}
                onClick={nextPeriod}>arrow_forward</Icon>
        </Box>

        {
          visibleForms.map(form =>
            <Box key={form.code}
                 width="7"
                 vertical
                 spacing="s"
                 alignItems="center">
              <Text block small>{form.code}</Text>
              <Icon center color="dark-4">lock_open</Icon>
            </Box>
          )
        }

        {
          hiddenForms.length > 0 &&
            <Text width="5" block small right>
              <Trans>+ {hiddenForms.length} more</Trans>
            </Text>
        }
      </Table.Header>

      <Box overflow="auto" flex="1" padding="0 0 0 0">
        {groupedPivots.map(({ name: group, items: assets }) =>
          <Box key={group}>
            {!!group &&
              <Table.Row style="highlight" borderCollapse={false}>
                <Text small strong color="dark-1" caps>{group}</Text>
              </Table.Row>
            }
            {assets.map(asset =>
              <Table.Row key={asset.id} style="regular" alignItems="center">
                <Box width="40" justify="start" horizontal spacing="s">
                  <Icon color="dark-4">lock_open</Icon>
                  <Text caps strong small color="dark-2">
                    {asset.code}
                  </Text>
                  <Text>{asset.name}</Text>
                </Box>
                {
                  visibleForms.map(form =>
                    <FormStatus key={form.code}
                                form={form}
                                pivot={asset}
                                dataPath={formDataPath(form.code)} />
                  )
                }
                {
                  hiddenForms.length > 0 &&
                    <Box width="5"></Box>
                }
              </Table.Row>
            )}
          </Box>
        )}
      </Box>
    </Layout>
  );
}
