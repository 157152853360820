import React, { useState, useRef, useEffect } from 'react';
import { Map } from '../types';
import {
  Box,
  Text,
  Items,
  Named,
  InferID,
  Dropdown,
  ItemProps,
  SelectList
} from '@just/jui';


interface BaseProps<T> {
  options?: T;
  selected?: { [K in keyof T]?: InferID<T[K]> };
  onChange?: (values: { [K in keyof T]?: InferID<T[K]> }) => void;
  commitOnSelect?: boolean;
  children?: React.ReactNode;
}

type Options = { [id: string]: Items };
type Props<T> = BaseProps<T> & (
  { value: string, label: string, children?: never }|
  { value?: never, label?: string, children: React.ReactNode }
);

export default function Switch<T extends Options>({
  label,
  value,
  options,
  selected: initiallySelected = {},
  onChange,
  commitOnSelect,
  children
}: Props<T>): React.ReactElement<Props<T>> {
  const [popupVisible, setPopupVisible] = useState(false);
  const [selected, setSelected] = useState(initiallySelected);

  const containerRef = useRef<HTMLElement>(null);
  const closePopup = useRef<() => void>();

  useEffect(hideOnClick, []);
  useEffect(() => {
    closePopup.current = () => {
      if (!popupVisible) return;

      setPopupVisible(false);
      didChange();
    };
  }, [popupVisible, selected]);

  children = children ||
    <>
      <Text block small>{label}</Text>
      <Text block
            color="light-1"
            small
            monospace
            strong
            caps>
        {value}
      </Text>
    </>

  return (
    <Box zIndex="4" ref={containerRef}>
      <Box cursor="pointer" onClick={showOptions}>
        {children}
      </Box>

      <Dropdown delay={0} visible={popupVisible}>
       <Box horizontal
            alignItems="baseline"
            pane="popup"
            spacing="xl"
            padding="m l"
            position="middle-left">

         <Text block caps color="light-3">{label}</Text>
         <Box spacing="m">
           {
             options && Object.entries(options).map(([option, items], i) =>
               <SelectList key={option}
                           items={items as Named[]}
                           item={SwitchItem}
                           selection={selected && selected[option]}
                           focus={popupVisible && i === 0}
                           onClose={(v) => change({ [option]: v })}
                           className=""
                           spacing="l"
                           horizontal />
             )
           }
         </Box>
       </Box>
      </Dropdown>
    </Box>
  );

  function showOptions() {
    setPopupVisible(true);
  }

  function change(values: Map<string|undefined>) {
    setSelected({...selected, ...values});
    if (commitOnSelect) {
      setPopupVisible(false);
      didChange(values);
    }
  }

  function hideOnClick() {
    const onClick = ({ target }: MouseEvent) => {
      const container = containerRef.current;

      if (container &&
          container !== target &&
          !container.contains(target as Node)) {
        closePopup.current?.();
      }
    };

    document.addEventListener('click', onClick);
    return () => { document.removeEventListener('click', onClick); };
  }

  function didChange(changes: Map<string|undefined> = {}) {
    const values = { ...selected, ...changes };

    const same = Object.keys(selected).every(key =>
          values[key] === initiallySelected[key]);

    if (!same) onChange?.(values);
  }
}

function SwitchItem({ item, selected, active }: ItemProps<Named>) {
  return (
    <Box borderColor="light-2"
         border={`0 0 ${selected ? 'w2' : '0'} 0` as any}>
      <Text color={(selected || active) && 'light-1'}
            weight={selected ? 'bold' : 'medium'}>
        {item.name || item.id}
      </Text>
    </Box>
  );
}
