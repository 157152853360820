import React from 'react';
import { Navigate } from 'react-router-dom';
import { Loading } from '../components';
import { useAPI } from '../api';
import { useAppContext, logout } from '../store';


export default function Signout(): React.ReactElement|null {
  const [_, dispatch] = useAppContext();
  const [api, loading] = useAPI({ load });

  return loading ? <Loading /> : <Navigate to="/signin" />;

  async function load() {
    await api.delete('/sessions/current');
  }
}
