import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { useAPI } from '../api';
import {
  Box,
  Text,
  Field,
  Button,
  Fieldset,
  validators as v
} from '@just/jui';
import { Frame, LocaleSwitch } from '../components';
import { useAppContext, login } from '../store';
import { Session } from '../types';


interface Props {
  to: string;
}

interface UserForm {
  login: string;
  password: string;
}

export default function Signin({
  to
}: Props): React.ReactElement<Props> {
  const [user, setUser] = useState<UserForm>({ login: '', password: '' });
  const [invalid, setInvalid] = useState(false);
  const [api, loading] = useAPI({ setInvalid });

  const [_, dispatch] = useAppContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => { restoreSession() }, []);


  const title =
    <Box horizontal margin="m">
      <Text color="dark-1" h1><Trans>Astring Reporting</Trans></Text>
      <LocaleSwitch>
        <Button><Trans>Language</Trans></Button>
      </LocaleSwitch>
    </Box>

  return (
    <Frame title={title} onSubmit={signin}>
      <Text block margin="l">
        <Trans>Don't have account yet?</Trans>
        <Text link padding="0 s">
          <Link to="/signup"><Trans>Register a new one.</Trans></Link>
        </Text>
      </Text>

      <Field label={t`E-Mail`}
             placeholder={t`Your e-mail address goes here`}
             required
             value={user.login}
             convert="lowercase"
             validate={[v.format(/^\S+@\S+$/i, t`email`)]}
             onChange={login => change({ login })} />
      <Field label={t`Password`}
             masked
             placeholder={t`Your password goes here`}
             required
             value={user.password}
             errors={invalid && t`is invalid`}
             onChange={password => change({ password })} />

      <Box horizontal justify="center" padding="m 0 0 0" spacing="l">
        <Button onClick={forgot}><Trans>Forgot password</Trans></Button>
        { loading
            ? <Text><Trans>Logging in...</Trans></Text>
            : <Button submit primary><Trans>Sign In</Trans></Button> }
      </Box>
    </Frame>
  );

  function forgot() {
    navigate('/forgot');
  }

  function change(input: Partial<UserForm>) {
    setUser({ ...user, ...input });
    setInvalid(false);
  }

  function setSession(session: Session) {
    dispatch(login(session));
    navigate(searchParams.get('to') || to);
  }

  async function restoreSession() {
    try { setSession(await api.get('/sessions')) }
    catch (e) { setInvalid(false) }
  }

  async function signin() {
    try {
      if (!user.login || !user.password) return setInvalid(true);
      setSession(await api.post('/sessions', { user }));
    } catch { setUser({ ...user, password: '' }); }
  }
}
