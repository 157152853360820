import { Dispatch as ReactDispatch } from 'react';
import { Row, Form } from '../types';


const INSERT_ROW = 'INSERT_ROW';
const CHANGE_ROW = 'CHANGE_ROW';
const DELETE_ROW = 'DELETE_ROW';
const SELECT_ROW = 'SELECT_ROW';
const CHANGE_FORM = 'CHANGE_FORM';
const SET_STATE = 'SET_STATE';

export const ROW_EDITOR = 'row';
export const FORM_EDITOR = 'form';
export const NOT_SELECTED = -1;

interface SelectRowAction {
  payload: { id: number };
  type: typeof SELECT_ROW,
}

interface ChangeRowAction {
  payload: { id: number, attrs: Partial<Row> };
  type: typeof CHANGE_ROW,
}

interface ChangeFormAction {
  payload: { attrs: Partial<Form> };
  type: typeof CHANGE_FORM,
}

interface InsertRowAction {
  payload: { row: Row };
  type: typeof INSERT_ROW,
}

interface DeleteRowAction {
  payload: { id: number };
  type: typeof DELETE_ROW,
}

interface SetStateAction {
  payload: { state: Partial<State> }
  type: typeof SET_STATE;
}

type EditorAction =
  | SelectRowAction | ChangeRowAction | InsertRowAction | DeleteRowAction
  | ChangeFormAction
  | SetStateAction;

export interface State {
  form: Form;
  selectedRowId: number;
  activeEditor?: null | typeof FORM_EDITOR | typeof ROW_EDITOR;
}

export type Dispatch = ReactDispatch<EditorAction>;

export function reducer(
  state: State,
  action: EditorAction
): State {
  const { rows, ...form } = state.form;

  let idx: number;
  let row: Row|null|undefined;

  switch (action.type) {
    case SET_STATE:
      return {...state, ...action.payload.state };
    case SELECT_ROW:
      idx = action.payload.id;
      row = idx >= 0 ? rows.find(row => row.row === idx) : null;
      return {
        ...state,
        activeEditor: row ? ROW_EDITOR : null,
        selectedRowId: row ? idx : NOT_SELECTED
      };
    case INSERT_ROW:
      row = action.payload.row;
      idx = row.row;

      if (rows.find(r => r.row === idx))
        return { ...state, activeEditor: ROW_EDITOR, selectedRowId: idx };

      return {
        ...state,
        form: {
          ...form,
          rows: [...rows, {
            ...row,
            code: formatCode(idx, form.code)
          }].sort(byCode)
        },
        activeEditor: ROW_EDITOR,
        selectedRowId: idx
      };
    case CHANGE_FORM:
      return {
        ...state,
        form: {
          ...form,
          rows,
          ...action.payload.attrs,
          code: (action.payload.attrs.code || form.code).toUpperCase()
        }
      };
    case CHANGE_ROW:
      idx = action.payload.attrs.row ?? action.payload.id;

      return {
        ...state,
        selectedRowId: idx,
        form: {
          ...form,
          rows: rows.map(row =>
            row.row === action.payload.id
              ? {
                  ...row,
                  ...action.payload.attrs,
                  code: formatCode(idx, form.code)
                }
              : row
          ).sort(byCode)
        }
      };
    case DELETE_ROW:
      idx = action.payload.id;

      return {
        ...state,
        form: { ...form, rows: rows.filter(r => r.row !== idx) },
        activeEditor: null
      };
    default:
      return state;
  }
}

function byCode(a: Row, b: Row) { return a.row - b.row }

function setState(state: Partial<State>): EditorAction {
  return { type: SET_STATE, payload: { state }};
}
export function insertNewRow(row: number): EditorAction {
  return insertRow({
    row,
    code: formatCode(row),
    name: 'New Row',
    style: 'regular',
    aggregate: 'SUM',
    intragroup: false,
    separated: false,
  });
}
export function selectRow(id: number): EditorAction {
  return { type: SELECT_ROW, payload: { id }};
}
export function insertRow(row: Row): EditorAction {
  return { type: INSERT_ROW, payload: { row }};
}
export function deleteRow(id: number): EditorAction {
  return { type: DELETE_ROW, payload: { id }};
}
export function changeRow(id: number, attrs: Partial<Row>): EditorAction {
  return { type: CHANGE_ROW, payload: { id, attrs }};
}
export function changeForm(attrs: Partial<Form>): EditorAction {
  return { type: CHANGE_FORM, payload: { attrs }};
}
export function hideAnyEditor(): EditorAction {
  return setState({ activeEditor: null });
}
export function showFormEditor(): EditorAction {
  return setState({ activeEditor: FORM_EDITOR, selectedRowId: NOT_SELECTED });
}

export function formatCode(row: string|number, form: string = '') {
  return form + ('000' + row).slice(-3);
}
