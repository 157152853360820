import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Button
} from '@just/jui';
import { useAPI } from '../api';
import { Report } from '../types';
import { Frame, Loading } from '../components';


export default function ReportsList(): React.ReactElement {
  const [api] = useAPI({ load });
  const [reports, setReports] = useState<Report[]>();

  const navigate = useNavigate();

  if (!reports) return <Loading />
  if (!reports.length) return <Navigate to="new" />

  return (
    <Frame title={<Box horizontal justify="start">
                    <Text block link h3 small caps>
                      <Link to="/accounts"><Trans>Accounts</Trans></Link>
                    </Text>
                    &nbsp;/&nbsp;
                    <Text block h1 color="dark-1">
                      <Trans>Reportings</Trans>
                    </Text>
                  </Box>}>
      <Box margin="m">
        <Text block>
          <Trans>
            Select an existing reporting or
            <Text link>
              <Link to="new"><Trans>create a new reporting.</Trans></Link>
            </Text>
          </Trans>
        </Text>
      </Box>
      <Box spacing="s" maxHeight="m" margin="xl" overflow="auto">
        {
          reports.map(({ id, code, name }) =>
            <Text key={id}>
              &bull;&nbsp;
              <Text link>
                <Link to={`${code}`}>{name}</Link>
              </Text>
            </Text>
          )
        }
      </Box>
      <Box horizontal>
        <Button onClick={newReporting}>
          <Trans>Create a new reporting</Trans>
        </Button>
      </Box>
    </Frame>
  );

  function newReporting() {
    navigate('new');
  }

  async function load() {
    try { setReports(await api.get('/reports')) }
    catch (e) {}
  }
}
