import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Text,
} from '@just/jui';
import { t } from '@lingui/macro';
import { Frame } from '../components';


interface Props {}

export default function Accounts(
  {}: Props
): React.ReactElement<Props> {
  return (
    <Frame title={t`Resource not found`}>

    </Frame>
  );
}
