import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { Box, Text, Field, Fieldset, Choice, Button } from '@just/jui';
import { Report } from './hooks';
import { Asset, PeriodShift, FormColumn } from '../types';
import { periodLabel, normalizePeriod } from '../utils';


interface Props {
  report: Report;
  column?: FormColumn;
  onChange: (column: FormColumn) => void;
}

const ColumnSettings = React.forwardRef((
  {
    report,
    column = {},
    onChange
  }: Props,
  forwardRef?: React.Ref<HTMLElement>
): React.ReactElement<Props> => {
  const params = useParams();

  const scenarios = report.scenario.assets;

  const defaultScenario =
    scenarios.find(a => a.code === params.scenario) || scenarios[0];

  const selectedScenario = scenarios.find(a => a.code === column.scenario);

  const period = normalizePeriod(params.period, report.period)
                 || normalizePeriod(new Date(), report.period)!;

  const change = (attrs: FormColumn) => onChange({ ...column, ...attrs });

  return (
    <Box ref={forwardRef}
         pane="shadow"
         background="light-5"
         color="dark-2"
         spacing="m"
         width="m"
         zIndex="4"
         padding="l"
         position="bottom-right">
      <Field label={report.scenario.name}
             placeholder={t`Same (${defaultScenario.name})`}
             from={report.scenario.assets}
             value={selectedScenario?.name}
             onChange={s => change({ scenario: s?.code })}
             margin="l" />

      <Fieldset label={t`Period`} spacing="s">
        <Choice onChange={period => change({ period })}
                value={column.period || ''}>
        {{
          'LY': t`Last year`,
          'LP': t`Last month`,
          'C': t`Same period (${periodLabel(period, report.period)})`,
          'NP': t`Next month`,
          'YE': t`End of the year`
        }}
        </Choice>
      </Fieldset>
    </Box>
  );
});

export default ColumnSettings;
