import React, { useState, useEffect, useCallback } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { Box, Text, Field, SelectList } from '@just/jui';
import { Segment, Asset, FormData } from '../types';
import { group } from '../utils';


interface Props {
  segment: number;
  getStatus: (segmentId: number, active: boolean) => React.ReactNode;
  onChange: (id?: number, asset?: Asset) => void;
}

const AssetSelect = React.forwardRef((
  {
    segment: segmentId,
    getStatus,
    onChange
  }: Props,
  forwardRef?: React.Ref<HTMLElement>
): React.ReactElement<Props>|null => {
  const { account } = useParams();
  const { data: segment } =
    useSWR<Segment>(`/accounts/${account}/segments/${segmentId}`);

  const [filter, setFilter] = useState('');
  const [assets, setAssets] = useState<Asset[]>([]);
  useEffect(() => setAssets(segment?.assets || []), [segment]);

  const Item = useCallback(
    ({ item: asset, active }: { item: Asset, active: boolean }) =>
      <Box horizontal>
        <Text block flex="1">{asset.name}</Text>
        <Text block>{getStatus(asset.id, active)}</Text>
      </Box>
  , [getStatus]);

  if (!segment) return null;

  return (
    <Box ref={forwardRef}
         pane="shadow"
         background="light-2"
         width="m"
         spacing="m">
      {
        segment.assets.length > 5 &&
          <Field icon="search"
                 value={filter}
                 placeholder={t`Type to search`}
                 onChange={filterAssets} />
      }
      <SelectList items={assets}
                  item={Item}
                  focus
                  maxHeight="l"
                  overflow="auto"
                  onClose={onChange} />
    </Box>
  );

  function filterAssets(filter: string) {
    const term = filter.toUpperCase();
    const allAssets = segment?.assets || [];

    setFilter(filter);
    setAssets(!filter ? allAssets : allAssets.filter(a =>
      a.code.startsWith(term) ||
      a.name.toUpperCase().startsWith(term)
    ));
  }
});

export default AssetSelect;
