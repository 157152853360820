import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Trans, t } from '@lingui/macro';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, Button  } from '@just/jui';
import { Select, GroupedSelect, Loading, Logo } from '../components';
import { useReport } from './hooks';
import { normalizePeriod, monthNames, periodCode, utc } from '../utils';
import { Asset, Segment } from '../types';


interface Params {
  year: number;
  month: number;
  scenario?: number;
  segment?: number;
  pivot?: number;
  form?: string;
}

export default function CampaignSelect(): React.ReactElement {
  const params = useParams();
  const navigate = useNavigate();

  const report = useReport()
  const [current, setCurrent] = useState<Params>();

  const currentSegmentId =
    report?.forms.find(f => f.code === current?.form)?.segmentId;

  const { data: loadedSegment } = useSWR<Segment>(() =>
    currentSegmentId &&
    (currentSegmentId !== report?.segment?.id)
      ? `/accounts/${params.account}/segments/${currentSegmentId}`
      : null
  );

  useEffect(() => {
    if (!report) return;

    const period = normalizePeriod(params.period, report.period) ||
                   normalizePeriod(new Date(), report.period)!;

    setCurrent({
      year: period.getUTCFullYear(),
      month: period.getUTCMonth(),
      scenario: report.scenario.assets
                  .find(s => s.code === params.scenario)?.id,
      pivot: report.pivot.assets
                  .find(s => s.code === params.pivot)?.id,
      segment: report.segment?.assets
                  .find(s => s.code === params.segment)?.id,
      form: report.forms
                  .find(f => f.code === params.form)?.code
    })
  }, [report]);

  if (!report || !current) return <Loading />

  const pivots = report.pivot.assets;
  const segment = loadedSegment || report.segment;
  const scenarios = report.scenario.assets;

  const forms = report.forms.map(
   ({ code, name }) => ({ id: code, code, name }));

  const isSegmented =
    !!report.forms.find(f => f.code === current.form)?.segmentId;

  const years = range(current.year - 4, current.year + 4).map(id => ({ id }));
  const months = monthNames().map((name, id) => ({ id, name }));

  const change = (attrs: Partial<typeof current>) =>
    setCurrent({ ...current, ...attrs });

  const back = () => navigate('..');

  const goTo = () =>
    navigate([
      '/', params.account,
      'reports', params.report,
      'campaigns',
      getCode(report.scenario.assets, current.scenario),
      periodCode(normalizePeriod(
                  utc(current.year, current.month),
                  report.period)!),
      current.form,
      getCode(report.pivot.assets, current.pivot),
      isSegmented && segment && getCode(segment.assets, current.segment)
    ].filter(Boolean).join('/')
  );

  return (
    <Box vertical
         align="center"
         maxWidth="l"
         height="screen"
         justify="center"
         alignItems="stretch"
         background="white"
         border="0 0 0 w3"
         padding="0 l 0 0"
         borderColor="accent-2"
         pane="shadow">
        <Box horizontal alignItems="center" spacing="s">
          <Box background="accent-1"
               padding="m m l m"
               width="s"
               vertical
               color="light-1"
               spacing="m">
             <Button primary icon="done" onClick={goTo}>
              <Trans>Apply</Trans>
            </Button>
            <Button icon="arrow_back" onClick={back}>
              <Trans>Back</Trans>
            </Button>
          </Box>
           <Box padding="m l l l" flex="1">
             <Text h1 line><Trans>What you would like to see?</Trans></Text>
           </Box>
        </Box>

        <Box flex="1" overflow="auto" vertical>
          <Block label={report.scenario.name}
                 value={getCode(scenarios, current.scenario)}>
            <Select options={scenarios}
                    selected={current.scenario}
                    onChange={scenario => change({ scenario })} />
          </Block>
          <Block label={t`Year`} value={current.year}>
            <Select options={years}
                    selected={current.year}
                    onChange={year => change({ year })} />
          </Block>
          <Block label={t`Month`} value={monthNames()[current.month]}>
            <Select options={months}
                    selected={current.month}
                    onChange={month => change({ month })} />
          </Block>
          <Block label={t`Form`}
                 value={current.form || t`All`}>
            <Select options={forms}
                    selected={current.form}
                    withCode
                    onChange={form => change({ form })} />
          </Block>
          {isSegmented && segment &&
            <Block label={segment.name}
                   value={getCode(segment.assets, current.segment) || t`All`}>
              <GroupedSelect groupBy={o => o.group}
                             options={segment.assets}
                             selected={current.segment}
                             withCode
                             onChange={segment => change({ segment })} />
            </Block>
          }
          <Block stretch
                 label={report.pivot.name}
                 value={getCode(pivots, current.pivot) || t`All`}>
            <GroupedSelect groupBy={o => o.group}
                           options={pivots}
                           selected={current.pivot}
                           onChange={pivot => change({ pivot })} />
          </Block>

          <Box fixed="bottom" alignItems="stretch" horizontal>
            <Box width="s"
                 background="accent-1"
                 vertical
                 justify="end">
              <Logo />
            </Box>
          </Box>
        </Box>
    </Box>
  );
}

const getCode = (assets: Asset[], id?: number) =>
  assets.find(a => a.id === id)?.code;

const Block = ({ label, value, stretch, children }: {
  label: string|React.ReactNode,
  value?: string|number,
  stretch?: boolean
  children: React.ReactNode
}) =>
  <Box horizontal
       flex={stretch ? '1' : undefined}
       justify="start"
       alignItems="stretch"
       spacing="s">
    <Box background="accent-1" padding="m" width="s">
      <Text block small caps strong color="light-2">{label}</Text>
      {value && <Text block caps strong color="light-1">{value}</Text>}
    </Box>
     <Box padding="m l" flex="1">{children}</Box>
  </Box>

const range = (start: number, end: number) =>
    Array.from({length: (end - start)}, (v, k) => k + start);
