import { i18n } from '@lingui/core';
import { detect, fromNavigator } from '@lingui/detect-locale'
import { en, ru } from 'make-plural'


export const locales = {
  en: 'English',
  ru: 'Русский',
};

i18n.loadLocaleData({
  en: { plurals: en },
  ru: { plurals: ru },
});

export function getDefaultLocale() {
  const fallback = () => 'en';
  const locale = detect(fromNavigator(), fallback) || '';

  return locales.hasOwnProperty(locale) ? locale : fallback();
}

export async function activateLocale(locale: string = 'en') {
  if (!locale || i18n.locale === locale) return;
  let messages = {};

  try {
    const localeSource = process.env.NODE_ENV === 'development'
      ? await import(`@lingui/loader!./locales/${locale}/messages.po`)
      : await import(`./locales/${locale}/messages`);

    messages = localeSource.messages;
  } catch (e) { console.error(e) }

  i18n.load(locale, messages);
  i18n.activate(locale);
}
