import React from 'react';
import { Box, Text } from '@just/jui';


interface Props {
  title: React.ReactNode;
  onSubmit?: () => void;
  children: React.ReactNode;
}

export default function Frame({
  title,
  onSubmit,
  children
}: Props): React.ReactElement<Props> {

  return (
    <Box padding="xl 0 0 0">
      <Box maxWidth="s"
           spacing="m"
           color="dark-2"
           align="center"
           background="light-1"
           border="0 0 0 w5"
           borderColor="accent-4"
           padding="l">

        {
          typeof title === 'string'
            ? <Text color="dark-1" h1 margin="m">{title}</Text>
            : title
        }
        {onSubmit
          ? <form onSubmit={submitForm}>
              <Box spacing="m">{children}</Box>
            </form>
          : children
        }
      </Box>
    </Box>
  );

  function submitForm(e: React.FormEvent) {
    e.preventDefault();
    onSubmit?.();
  }
}
