import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Loading } from '../components';
import { useAPI } from '../api';
import { Form } from '../types';
import FormEditor from './FormEditor';


export default function EditForm(): React.ReactElement {
  const { code } = useParams();
  const [api] = useAPI({ load });
  const navigate = useNavigate();

  const [form, setForm] = useState<Form>();
  const url = `/forms/${code}`;

  if (!form)
    return (
      <Loading>
        <Trans>Loading form "{code}", please wait...</Trans>
      </Loading>
    );

  return <FormEditor form={form}
                     onSave={save}
                     onCancel={back} />

  async function load() {
    setForm(undefined);
    try { setForm(await api.get(url)); }
    catch (e) {
      console.error(e);
      navigate('../..');
    }
  }

  async function save(form: Form) {
    try {
      await api.patch(url, { form });
      back();
    }
    catch (e) { console.error(e); }
  }

  function back() {
    navigate('../..');
  }
}
