import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Loading } from '../components';
import { useAPI } from '../api';
import { Segment } from '../types';
import SegmentEditor from './SegmentEditor';

type State = { segment?: Segment };

export default function EditForm(): React.ReactElement {
  const { segmentId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const { segment: initialSegment }: State = location.state as State || {};

  const [api] = useAPI({ withReport: false });

  const [segment, setSegment] = useState<Segment>();
  useEffect(() => { load() }, [segmentId]);

  const url = `/segments/${segmentId}`;
  const back = '../..';

  if (!segment)
    return <Loading><Trans>Loading segment, please wait...</Trans></Loading>

  return <SegmentEditor segment={segment}
                        onDone={done} />

  async function load() {
    if (initialSegment) return setSegment(initialSegment);

    setSegment(undefined);

    try { setSegment(await api.get(url)); }
    catch (e) {
      console.error(e);
      navigate(back);
    }
  }

  function done() {
    navigate(back);
  }
}
