import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { useAPI } from '../api';
import {
  Box,
  Text,
  Field,
  Button,
  validators as v
} from '@just/jui';
import { Frame } from '../components';


export default function ForgotPassword(): React.ReactElement {
  const [email, setEmail] = useState('');
  const [requested, setRequested] = useState(false);
  const [api, loading] = useAPI();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Frame title={t`Astring Reporting - Restore Password`} onSubmit={reset}>
      {requested
        ? <Text block margin="m">
            <Trans>
              An email has been sent to the associated email address
              with further instructions.
            </Trans>
          </Text>
        : <Field label={t`E-Mail`}
                 focus
                 placeholder={t`Registered email address`}
                 required
                 value={email}
                 validate={[v.email]}
                 onChange={setEmail} />
      }

      <Box horizontal justify="center" padding="m 0 0 0" spacing="m">
        {
          requested
          ? <Button onClick={back}>
              <Trans>Back to login page</Trans>
            </Button>
          : <Button submit primary>
              <Trans>Reset password</Trans>
            </Button>
        }
      </Box>
    </Frame>
  );

  function back() {
    navigate('/signin');
  }

  async function reset() {
    if (!email) return;

    try {
      await api.put(`/users/${encodeURIComponent(email)}`);
      setRequested(true);
    } catch (e) { console.error(e); }
  }
}
