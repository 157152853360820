import React, { useEffect, useState, useMemo } from 'react';
import { Box, Text, Button, Field, Fieldset, Checkbox } from '@just/jui';
import { useAPI } from '../api';


export namespace Resource {
  export interface EditorProps<T> {
    item: T;

    onDelete?: (item: T) => void;
    onCancel?: () => void;
    onSave?: (item: T) => void;
  }

  export type Editor<T> = React.FC<EditorProps<T>>;

  export type Item<T> = React.FC<{
    item: T;
    onClick?: () => void;
  }>;

  interface BaseProps<T> {
    headers: React.ReactFragment;

    Item: Resource.Item<T>;

    groupBy?: keyof T|((item: T) => string);
    orderBy?: keyof T|((a: T, b: T) => number);
  }

  interface PropsWithURL<T> extends BaseProps<T> {
    url: string;
    items?: never;
  }

  interface PropsWithItems<T> extends BaseProps<T> {
    url?: never;
    items: T[];
  }

  type WithEditor<T> = {
    Editor: Resource.Editor<T>;
    editor?: never;
  } | {
    Editor?: never;
    editor: (props: EditorProps<T>) => React.ReactElement;
  };

  type Props<T> = WithEditor<T> & (PropsWithURL<T> | PropsWithItems<T>);

  export function View<T extends { id?: string|number }>({
    url,
    Item,
    items: initialItems = [],
    Editor,
    editor: editorFn,
    headers,
    groupBy,
    orderBy = 'id'
  }: Props<T>): React.ReactElement<Props<T>> {
    type Groups = { [name: string]: T[] };

    const [api] = useAPI();
    const [selected, setSelected] = useState<T|null>(null);

    const [items, setItems] = useState<T[]>([]);
    const [groupedItems, setGroupedItems] = useState<Groups>({});

    useEffect(() => { load() }, [initialItems]);
    useEffect(groupAndSort, [items]);

    const renderEditor = useMemo(() =>
      Editor
        ? (props: EditorProps<T>) => <Editor key={props.item.id} {...props} />
        : editorFn!
    , [Editor, editorFn]);

    return (
      <>
        <Box horizontal
             border="0 0 w2 0"
             borderColor="black-2"
             borderCollapse
             padding="s 0"
             spacing="s">
          {headers}
        </Box>
        <Box flex="1" overflow="auto" padding="0 0 0 0">
          {
            Object.entries(groupedItems).map(([group, items]) =>
              <Box key={group}>
                {
                  group &&
                    <Box border="0 0 dotted 0"
                         borderCollapse
                         padding="m"
                         background="dark-6">
                      <Text h3 strong>{group}</Text>
                    </Box>
                }
                {
                  items.map(item => selected && selected.id === item.id
                    ? renderEditor({
                        item,
                        onCancel: () => select(null),
                        onDelete: removeFromList,
                        onSave: updateList
                      })
                    : <Item key={item.id}
                            item={item}
                            onClick={() => select(item)} />
                  )
                }
              </Box>
            )
          }
        </Box>
      </>
    );

    function groupAndSort() {
      const sortFn = typeof orderBy === 'function'
        ? orderBy
        : (a: T, b: T) => (a[orderBy] || '') > (b[orderBy] || '') ? 1 : -1;

      const groupFn = typeof groupBy === 'function'
        ? groupBy
        : (item: T) => (groupBy ? item[groupBy] : '') as string;

      if (!items || !items.length)
        return setGroupedItems({});

      const groups = items.reduce((groups, item) => {
        const group = groupFn(item);
        groups[group] = [...(groups[group] || []), item];
        return groups;
      }, {} as Groups);

      setGroupedItems(Object.keys(groups).sort().reduce((sorted, key) => {
        sorted[key] = orderBy ? groups[key].sort(sortFn) : groups[key];
        return sorted;
      }, {} as Groups));
    }

    function removeFromList(item: T) {
      setSelected(null);
      setItems(items.filter(i => i.id === item.id));
    }

    function updateList(item: T) {
      const isNew = !items.find(s => s.id === item.id);

      setSelected(null);
      setItems(isNew
        ? [...items, item]
        : items.map(s => s.id === item.id ? item : s)
      );
    }

    function select(item: T|null) {
      setSelected(item);
    }

    async function load() {
      if (!url) return setItems(initialItems);

      try { setItems(await api.get(url)); }
      catch (e) { console.error(e); }
    }
  }
}
