import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { Box, Text, Field, Button, validators as v } from '@just/jui';
import { Account } from '../types';
import { useAPI, FieldErrors } from '../api';
import { Frame } from '../components';


interface Props {

}

export default function AccountForm({

}: Props): React.ReactElement<Props> {
  const [account, setAccount] = useState<Account>({ id: '' });
  const [errors, setFieldErrors] = useState<FieldErrors>(null);
  const [api, loading] = useAPI({ setFieldErrors });
  const navigate = useNavigate();

  return (
    <Frame title={t`New Account`}>
      <Box horizontal padding="l 0 0 0" alignItems="start">
        <Field label={t`ID - unique location`}
               placeholder={t`Ex: my-uniq-report`}
               required
               convert="lowercase"
               validate={[v.slug]}
               value={account.id}
               errors={errors?.id}
               onChange={id => change({ id })} />
        <Field label={t`Subscription`}
               from={[t`Free`]}
               value={t`Free`}
               onChange={template => {}} />
      </Box>

      <Box horizontal justify="center" padding="l 0 0 0">
        <Button onClick={create}>
          <Trans>Create account</Trans>
        </Button>
        <Box flex="1" />
        <Button onClick={signout}>
          <Trans>Sign-out</Trans>
        </Button>
      </Box>
    </Frame>
  );

  function signout() {
    navigate('/signout');
  }

  async function create() {
    try {
      await api.post('/accounts', { account });
      navigate(`/${account.id}/reports`);
    }
    catch (e) {}
  }

  function change(input: Partial<Account>) {
    setFieldErrors(null);
    setAccount({ ...account, ...input });
  }
}
