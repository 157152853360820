import React from 'react';
import { t } from '@lingui/macro';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text  } from '@just/jui';
import { Report } from './hooks';
import { periodLabel } from '../utils';


interface Props {
  report: Report
  lock?: boolean;
}

export default function ParamsInfo({
  report,
  lock
}: Props): React.ReactElement<Props> {
  const { scenario, period, pivot, form, segment } = useParams();
  const navigate = useNavigate();

  const select = () => navigate('select');

  return (
    <Box spacing="m" cursor="pointer" onClick={lock ? undefined : select}>
      <Value label={report.scenario.name}>{scenario}</Value>
      <Value label={t`Period`}>
        {periodLabel(period, report.period)}
      </Value>
      {form && <Value label={t`Form`}>{form}</Value>}
      {pivot && <Value label={report.pivot.name}>{pivot}</Value>}
      {
        segment && report.segment &&
          <Value label={report.segment.name}>{segment}</Value>
      }
    </Box>
  );
}

const Value = ({ label, children }: {
  label: string,
  children: string|number
}) =>
  <Box>
    <Text block color="light-2" caps small>{label}</Text>
    <Text block color="light-1" strong caps>{children}</Text>
  </Box>
