import React from 'react';
import {
  useNavigate,
  Navigate
} from 'react-router-dom';
import { Segment } from '../types';
import { useAPI } from '../api';
import { Layout } from '../components';
import SegmentForm from './SegmentForm';


export default function NewSegment(): React.ReactElement {
  const navigate = useNavigate();
  const [api] = useAPI({ withReport: false });

  const newSegment: Segment = {
    id: 0,
    name: '',
    isPivot: false,
    codeFormat: '',
    assets: []
  };

  return (
    <Layout>
      <SegmentForm segment={newSegment}
                   onSave={createSegment}
                   onCancel={cancel} />
    </Layout>
  );

  async function createSegment(maybeWithId: Segment) {
    const { id, ...segment } = maybeWithId;

    if (!segment.name || !segment.codeFormat) return;
    const savedSegment: Segment = await api.post(`/segments`, { segment });
    navigate(`../${savedSegment.id}`, { state: { segment: savedSegment }});
  }

  function cancel() {
    navigate('../..');
  }
}
