import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { useAPI } from '../api';
import { Dispatch, changeForm, hideAnyEditor } from './reducer';
import {
  Box,
  Text,
  Field,
  Button,
  validators as v
} from '@just/jui';
import { Form, Segment } from '../types';


interface Props {
  form: Form,
  dispatch: Dispatch,
}

export default function FormHeaderEditor({
  form,
  dispatch,
}: Props): React.ReactElement<Props> {
  const [api] = useAPI({ load, silient: true, withReport: false });
  const [segments, setSegments] = useState<Segment[]>();
  const [newSegmentName, setNewSegmentName] = useState('');

  const segmentLabel = ({ segmentId }: Form) =>
    segments
      ? newSegmentName || segments.find(s => s.id === segmentId)?.name
      : null;

  return (
    <Box zIndex="2" padding="m 0 0 0">
      <Box background="dark-6"
           padding="0 0 l 0"
           border="0 w3"
           borderColor="dark-5">
        <Box horizontal justify="end" padding="s s 0 0">
          <Button icon="visibility_off" onClick={hide}>
            <Trans>Hide</Trans>
          </Button>
        </Box>

        <Box padding="s"
             spacing="l"
             justify="start"
             alignItems="start">

          <Box horizontal
               justify="start"
               alignItems="start"
               spacing="m">

            <Field label={t`Code`}
                   width="10"
                   required
                   convert="uppercase"
                   validate={[v.format(/^[A-Z]{3}$/, t`not 3 letters`)]}
                   value={form.code}
                   onChange={code => change({ code })} />

            <Field label={t`Name`}
                   flex="1"
                   required
                   value={form.name}
                   onChange={name => change({ name })} />

            <Field label={t`Segment by`}
                   width="30"
                   from={segments}
                   errors={newSegmentName && [
                     t`segment does not exist`,
                     t`click the "NEW" button to create it`
                   ]}
                   value={segmentLabel(form)}
                   placeholder={
                     segments
                       ? t`Not segmented`
                       : t`Loading...`
                   }
                   validateNewSuggestion={v.length(3, 50)}
                   onNewSuggestion={setNewSegmentName}
                   onChange={selectSegment}
                   suffix={
                     newSegmentName
                       ? <Button onClick={createSegment}>
                           <Trans>New</Trans>
                         </Button>
                       : undefined
                   }/>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  async function load() {
    setSegments(await api.get('/segments') || []);
  }

  async function createSegment() {
    const segment = { name: newSegmentName, isPivot: false, codeFormat: 'X' };
    const newSegment = await api.post<Segment>('/segments', { segment });

    setSegments((segments || []).concat(newSegment));
    setNewSegmentName('');
    change({ segmentId: newSegment.id });
  }

  function selectSegment(segment?: Segment) {
    setNewSegmentName('');
    change({ segmentId: segment?.id });
  }

  function hide() {
    dispatch(hideAnyEditor());
  }

  function change(attrs: Partial<Form>) {
    dispatch(changeForm(attrs));
  }
}
