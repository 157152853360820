import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Text,
  Dropdown,
} from '@just/jui';


interface Props {
  label: string;
  value?: string;
  onClose?: () => void;
  children: React.ReactNode;
}

export default function PopupButton({
  label,
  value,
  onClose,
  children
}: Props): React.ReactElement<Props> {
  const [popupVisible, setPopupVisible] = useState(false);

  const containerRef = useRef<HTMLElement>(null);
  const closePopup = useRef<() => void>();

  useEffect(hideOnClick, []);
  useEffect(() => {
    closePopup.current = () => {
      if (!popupVisible) return;

      setPopupVisible(false);
      onClose?.();
    };
  }, [popupVisible]);

  return (
    <Box zIndex="4" ref={containerRef}>
      <Box cursor="pointer" onClick={showPopup}>
        <Text block small>{label}</Text>
        {value &&
          <Text block small monospace strong caps color="light-1">
            {value}
          </Text>
        }
      </Box>

      <Dropdown delay={0} visible={popupVisible}>
        <Box pane="popup"
             background="light-1"
             spacing="xl"
             padding="m l"
             position="middle-right">
          {children}
        </Box>
      </Dropdown>
    </Box>
  );

  function showPopup() {
    setPopupVisible(true);
  }

  function hideOnClick() {
    const onClick = ({ target }: MouseEvent) => {
      const container = containerRef.current;

      if (container &&
          container !== target &&
          !container.contains(target as Node)) {
        closePopup.current?.();
      }
    };

    document.addEventListener('click', onClick);
    return () => { document.removeEventListener('click', onClick); };
  }
}
