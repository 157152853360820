import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Trans, t } from '@lingui/macro';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Box, Text, Button } from '@just/jui';
import { NotFound, Layout, Table, Loading } from '../components';
import { FormData, Asset, Map } from '../types';
import { useAPI } from '../api';
import { normalizePeriod, periodLabel, format } from '../utils';
import { useReport, useForm, useData, dataPath, DataQuery } from './hooks';
import NumericInput from './NumericInput';
import FormRow from './FormRow';


const byCode = (code: string) => (a: Asset) => a.code === code;

export default function FormInput(): React.ReactElement {
  const navigate = useNavigate();
  const params = useParams();
  const report = useReport();
  const form = useForm();
  const [api] = useAPI({ withAccount: false, withReport: false });
  const [loadedData, error] = useData();

  const [input, setInput] = useState<Map<number>>({});

  const rowCodes = useMemo(() => form ?
    form.rows.filter(r => !r.formula).map(r => r.code) : [], [form]);

  const onInputKeyDown = useCallback((e: React.KeyboardEvent) => {
    let mod = 0;

    if (e.key === 'Enter' || e.key === 'ArrowDown') mod = 1;
    else if (e.key === 'ArrowUp') mod = -1;

    if (!mod) return;

    let idx = rowCodes.indexOf((e.target as HTMLInputElement).id) + mod;
    if (idx < 0) idx = rowCodes.length - 1;
    else if (idx >= rowCodes.length) idx = 0;

    document.getElementById(rowCodes[idx])?.focus();
  }, [form]);

  const data = loadedData === null ? {} as FormData : loadedData;
  if (!report || !form || !data || !input) return <Loading />


  const segmentAsset = report.segment?.assets
    .find(a => a.code === params.segment);

  const pivot = report.pivot.assets.find(byCode(params.pivot));
  const period = normalizePeriod(params.period, report.period);
  const scenario = report.scenario.assets.find(byCode(params.scenario));
  if (!pivot || !period || !scenario) return <NotFound />;
  if (pivot.isConsolidator) return <Navigate to=".." />;

  const label = (period: Date) => periodLabel(period, report.period);
  const value = (row: string) => (input[row] || data[row]) as number;

  const save = async () => {
    try {
      const url = dataPath({ ...params, ...{} } as DataQuery);
      await api.put(url, { data: input });
      navigate('..');
    }
    catch (e) { console.error(e) }
  };

  const cancel = () => navigate('..');

  const change = (row: string, value: number) => {
    setInput({ ...input, [row]: value });
  };

  return (
    <Layout buttons={<>
              <Button primary icon="done" onClick={save}>
                <Trans>Save</Trans>
              </Button>
              <Button icon="clear" onClick={cancel}>
                <Trans>Cancel</Trans>
              </Button>
            </>}>
      <Table.Header>
        <Box width="10">
          <Text block strong>{form.code}</Text>
        </Box>
        <Box width="35" spacing="s">
          <Text block strong caps>{form.name}</Text>
          <Text block color="dark-2" small>
            <Text strong>{pivot.code}</Text>
            &nbsp; - {pivot.name}
          </Text>
          {
            segmentAsset &&
              <Text block color="dark-2" small>
                <Text strong>{segmentAsset.code}</Text>
                &nbsp; - {segmentAsset.name}
              </Text>
          }
        </Box>
        <Box width="3" />
        <Box width="15" />
        <Box width="15">
          <Text block strong small right>{label(period)}</Text>
          <Text block strong small right>kUSD, {scenario.name}</Text>
        </Box>
        <Box width="15" color="dark-4" />
        <Box width="3" />
      </Table.Header>

      <Table.Content>
        {
          form.rows.map((row, i) =>
            <FormRow key={row.code}
                     row={row}>
              <Box width="15" />
              <Text block width="15" strong color="accent-3" right as="div">
                {
                  row.formula
                    ? '—'
                    : <NumericInput id={row.code}
                                    autoFocus={i === 0}
                                    onKeyDown={onInputKeyDown}
                                    value={value(row.code) || 0}
                                    onChange={v => change(row.code, v)} />
                }
             </Text>
             <Box width="15" />
             <Box width="3" />
           </FormRow>
          )
        }
      </Table.Content>
    </Layout>
  );
}
