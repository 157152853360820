import React from 'react';
import Switch from './Switch';
import { Text, InferID } from '@just/jui';
import { t, Trans } from '@lingui/macro';
import { useAppContext, setLocale } from '../store';
import { Locale } from '../types';


interface Props {
  children?: React.ReactElement
}

export default function LocaleSwitch({
  children
}: Props): React.ReactElement<Props> {
  const [{ locale }, dispatch] = useAppContext();

  const locales = {
    en: t`English`,
    ru: t`Russian`
  };

  return (
    <Switch
      label={t`Language`}
      options={{ locale: locales }}
      selected={{ locale: locale as string }}
      commitOnSelect
      onChange={({locale}) => dispatch(setLocale(locale as Locale))}>
      {children || <Text block caps><Trans>Language</Trans></Text>}
    </Switch>
  );
}
