import React from 'react';
import { Box, Button, BoxProps } from '@just/jui';

const rowStyles = {
  header: {
    spacing: 's',
    padding: '0',
    border: '0 0 w2 0',
    borderColor: 'black-1'
  },
  regular: {
    spacing: 's',
    padding: 's 0',
    border: 'dotted 0 0 0'
  },
  highlight: {
    spacing: 's',
    padding: 's 0',
    border: 'dotted 0 0 0',
    background: 'dark-6',
  },
  border: {
    spacing: 's',
    padding: 's 0',
    border: '0 w2',
    borderColor: 'dark-4',
    background: 'dark-6',
  }
} as const;

interface Props {
  children?: React.ReactNode;
}

interface RowProps {
  style?: keyof typeof rowStyles;
  alignItems?: BoxProps['alignItems'];
  onClick?: (e: React.MouseEvent) => void;
  borderCollapse?: boolean;
  children?: React.ReactNode;
}

interface FormProps {
  borderCollapse?: boolean;
  buttons?: React.ReactNode;
  children?: React.ReactNode;
}

export function Header({
  children
}: Props): React.ReactElement<Props> {
  return (
    <Box horizontal
         justify="start"
         border="0 0 w2 0"
         borderColor="black-2"
         borderCollapse
         padding="s 0"
         spacing="s">
      {children}
    </Box>
  );
}

export function Content({
  children
}: Props): React.ReactElement<Props> {
  return (
    <Box flex="1" overflow="auto" padding="0 0 0 0">
      {children}
    </Box>
  );
}

export function Row({
  style = 'regular',
  alignItems = 'baseline',
  borderCollapse = true,
  children,
  ...props
}: RowProps): React.ReactElement<RowProps> {
  return (
    <Box borderCollapse={borderCollapse}
         horizontal
         justify="start"
         alignItems={alignItems}
         {...rowStyles[style]}
         {...props}>
      {children}
    </Box>
  );
}

export function Form({
  buttons,
  borderCollapse = true,
  children
}: FormProps): React.ReactElement<FormProps> {
  return (
    <Box zIndex="1"
         background="dark-6"
         padding="m 0"
         border="0 w3"
         borderColor="dark-5"
         borderCollapse={borderCollapse}>

      {buttons &&
        <Box horizontal
             justify="end"
             spacing="m"
             padding="0 0 0 l"
             position="bottom-right"
             background="white">
          {buttons}
        </Box>
      }
      {children}
    </Box>
  );
}
