import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Box, Text } from '@just/jui';
import { Report } from '../types';


interface Props {
  report: Report;
  hasForms?: boolean;
  hasPivots?: boolean;
  hasScenarios?: boolean;
  onNewForm: () => void;
}

export default function EmptyReportGuide({
  report,
  hasForms,
  hasPivots,
  hasScenarios,
  onNewForm
}: Props): React.ReactElement<Props> {
  return (
    <Box margin="xl" spacing="m">
      <Text h4>
        <Trans>A few steps before the first reporting campaign:</Trans>
      </Text>

      {!hasForms &&
        <Box>
          <Trans>
            <Text block strong link onClick={onNewForm}>
              <Trans>Create the first form</Trans>
            </Text>
            <Text block small>
              Form defines data to be reported
            </Text>
          </Trans>
        </Box>
      }
      {!hasPivots &&
        <Box>
          <Trans>
            <Text block strong link>
              <Link to={`segments/${report.pivotId}`}>
                <Trans>Create pivot segment values</Trans>
              </Link>
            </Text>
            <Text block small>
              Forms are filled for each value
            </Text>
          </Trans>
        </Box>
      }
      {!hasScenarios &&
        <Box>
          <Trans>
            <Text block strong link>
              <Link to={`segments/${report.scenarioId}`}>
                <Trans>Create at least one scenario</Trans>
              </Link>
            </Text>
            <Text block small>
              Scenario is used together with period to segregate reports
            </Text>
          </Trans>
        </Box>
      }
    </Box>
  );
}
