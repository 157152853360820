import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  css,
  Box,
  Text,
  Named,
  Dropdown,
  debounce,
  ItemProps,
  escapeHtml,
  SelectList,
  ContentEditable
} from '@just/jui';
import useSWR from 'swr';
import { Row, Map } from '../types'
import { format } from '../../../shared/expression/parser';
import './ExpressionEditor.css';


const INPUT = 'input';
const DROPDOWN = 'dropdown';

type Focus = typeof INPUT|typeof DROPDOWN|null;
type Range = [number, number];
type RowIndex = Map<Row>;

interface SuggestedRow extends Named { alias?: string }

interface Props {
  rows: Row[]|string;
  value: string;
  delay?: number;
  multiline?: boolean;
  placeholder?: string;
  onChange?: (value: string, errors?: string[]) => void;
}

export default function ExpressionEditor({
  rows: sourceOrRows,
  value,
  onChange,
  multiline,
  placeholder,
  delay = 0
}: Props): React.ReactElement<Props> {
  const isRemote = typeof sourceOrRows === 'string';
  const [searchTerm, setSearchTerm] = useState('');

  const sourceURL = isRemote && searchTerm.length > 2
    ? `${sourceOrRows}?term=${encodeURIComponent(searchTerm)}`
    : null;

  const { data: requestedRows } = useSWR<Row[]>(sourceURL, {
    shouldRetryOnError: false,
    revalidateOnFocus: false
  });

  const rows = isRemote ? requestedRows || [] : sourceOrRows as Row[];

  const codeIndex: RowIndex = useMemo(() => rows.reduce((index, row) =>
    index.hasOwnProperty(row.code)
      ? index
      : { ...index, [row.code]: row }
  , codeIndex || {}), [rows]);

  const aliasIndex: RowIndex = useMemo(() => rows.reduce((index, row) =>
    !row.alias || index.hasOwnProperty(row.alias)
      ? index
      : { ...index, [row.alias]: row }
  , aliasIndex || {}), [rows]);

  const codeToAlias = useMemo(() => ({
    variable(code: string) {
      const row = codeIndex[code.toUpperCase()];
      return row?.alias || code;
    }
  }), [codeIndex]);

  const aliasToView = useMemo(() => ({
    variable(name: string) {
      const term = name.trim().toUpperCase();
      const row = codeIndex[term] || aliasIndex[term];

      if (!row) return isRemote
        ? `<span class="ee__v">${name}</span>`
        : `<span class="ee__v-unknown">${name}</span>`;

      const code = row.alias ? `data-code="${row.code}"` : '';
      const title = `title="${escapeHtml(row.name)}"`;

      return `<span class="ee__v" ${code} ${title}>${name}</span>`;
    }
  }), [isRemote, codeIndex, aliasIndex]);

  const aliasToCode = useMemo(() => ({
    variable(alias: string) {
      const row = aliasIndex[alias.toUpperCase()];
      return row?.code || alias;
    },

    operator(op: string) {
      return '()'.includes(op) ? op : ` ${op} `;
    },

    empty: () => ''
  }), [aliasIndex]);

  const [suggestions, setSuggestions] = useState<SuggestedRow[]>([]);
  const [focus, setFocus] = useState<Focus>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [activeWord, setActiveWord] = useState('');
  const cursor = useRef<Range>([0, 0]);

  const [source, setSource] = useState(format(value, codeToAlias));
  useEffect(() => setSource(format(value, codeToAlias)), [value]);

  const [styledSource, setStyledSource] = useState(format(source, aliasToView));
  useEffect(() => setStyledSource(format(source, aliasToView)), [source]);

  const updateSuggestions = useCallback(debounce(delay, (q: string) => {
    if (isRemote && (!searchTerm || !q.startsWith(searchTerm)))
      return setSearchTerm(q);

    const rows = Object.values(codeIndex);

    const isCode = !isNaN(+q[0]);
    const isSearchTerm = q === searchTerm;

    const suggestions = isSearchTerm
      ? rows
      : isCode
        ? suggestByCode(q)
        : suggestByName(q);

    setSuggestions(
      (suggestions.length ? suggestions : rows)
        .slice(0, 10)
        .map(r => ({ id: r.code, name: r.name })));

    function suggestByCode(q: string) {
      return rows.filter(r => r.code.includes(q.toUpperCase()));
    }

    function suggestByName(q: string) {
      return rows.filter(r => {
        const term = q.toLowerCase();
        const name = r.name.toLowerCase();
        return name.startsWith(term) || name.includes(' ' + term);
      });
    }
  }), [codeIndex, setSuggestions]);

  useEffect(() => { updateSuggestions(activeWord) }, [activeWord, codeIndex]);

  return (
    <div className={css([
                         'jui-suggest',
                         'jui-input-text',
                         'jui-field__input',
                        ],
                        {
                          'options-visible': dropdownVisible
                        })}>
      <ContentEditable value={styledSource}
                       placeholder={placeholder}
                       selection={cursor.current}
                       className={css(['ee', 'jui-input-text__input'],
                                      { multiline })}
                       focus={focus === INPUT}
                       onBlur={onInputBlur}
                       onInput={sourceDidChange}
                       onKeyDown={onKeyDown}
                       onSelection={trackSelection} />

        <Dropdown visible={dropdownVisible}>
          <SelectList items={suggestions}
                      item={RowItem}
                      focus={focus === DROPDOWN}
                      onBlur={closeAndReset}
                      onClose={closeAndSelect}
                      onPrint={closeAndPrint} />
        </Dropdown>
    </div>
  );

  function setCursorRange(r: number|Range) {
    cursor.current = Array.isArray(r) ? r : [r, r];
  }

  function trackSelection(selectionRange: Range) {
    setCursorRange(selectionRange);
  }

  function sourceDidChange(v: string, r: Range) {
    setSource(v);
    setCursorRange(r);
  }

  function closeAndReset() {
    setDropdownVisible(false);
    setFocus(null);
  }

  function closeAndSelect(id: string = '', v?: { alias?: string }) {
    if (!id) {
      setDropdownVisible(false);
      setFocus(INPUT);
      return;
    }

    const { word, index: insertAt } = getWordAt(source, cursor.current[0]);
    const suggestion = v?.alias || id;
    const updatedSource =
      source.substr(0, insertAt) +
      suggestion +
      source.substr(insertAt + word.length);

    const cursorTo = insertAt + suggestion.length;
    setCursorRange([cursorTo, cursorTo]);

    setSource(updatedSource);
    setDropdownVisible(false);
    setFocus(INPUT);
  }

  function closeAndPrint(c: string) {
    setSource(c === 'Backspace' ? source.slice(0, -1) : source + c);
    setDropdownVisible(false);
    setFocus(INPUT);
  }

  function showDropdown() {
    const { word } = getWordAt(source, cursor.current[0]);
    setActiveWord(word.trim());

    setDropdownVisible(true);
    setFocus(DROPDOWN);
  }

  function onInputBlur() {
    if (focus === DROPDOWN) return;
    if (focus === INPUT) setFocus(null);

    setDropdownVisible(false);

    if (!onChange) return;
    onChange(format(source, aliasToCode), validate(source));
  }

  function validate(source: string) {
    const errors: string[] = [];

    format(source, {
      variable(name) {
        const term = name.toUpperCase().trim();
        const known = aliasIndex[term] || codeIndex[term];
        if (!isRemote && !known) errors.push(`${name} is unknown`);
        return '';
      }
    });

    return errors;
  }

  function onKeyDown(e: React.KeyboardEvent) {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        break;
      case 'Escape':
        setDropdownVisible(false);
        break;
      case 'ArrowDown':
        e.preventDefault();
        e.stopPropagation();
        showDropdown();
        break;
    }
  }
}

function getWordAt(text: string, at: number = text.length) {
  const STOPS = '+*-/() .';
  let from = at;
  let to = at;
  let char;

  while (char = text[--from])
    if (STOPS.includes(char)) break;
  while (char = text[++to])
    if (STOPS.includes(char)) break;

  return {
    word: text.substring(from + 1, to),
    index: from + 1
  };
}

function RowItem({ item: row, active}: ItemProps<SuggestedRow>) {
  return (
    <Box horizontal>
      <Text color={active && 'dark-1'} flex="1">{row.name}</Text>
      <Text color={active && 'accent-1'}>{row.id}</Text>
    </Box>
  );
}
