import React from 'react';
import { Box, Text } from '@just/jui';
import { Trans } from '@lingui/macro';


interface Props {
  children?: React.ReactNode;
}

export default function Loading({
  children = 'Loading... Please wait.'
}: Props): React.ReactElement {
  return (
    <Box>
      <Text><Trans>Loading... Please wait.</Trans></Text>
    </Box>
  );
}
