import React, { useEffect, Suspense } from 'react';
import { SWRConfig } from 'swr';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import * as Session from './users';
import * as Reports from './reports';
import * as Accounts from './accounts';
import * as Segments from './segments';
import * as Campaigns from './campaigns';
import * as FormBuilder from './form-builder';
import { RestrictedRoutes, NotFound, Loading } from './components';
import { useAppReducer, AppContext } from './store';
import { apiRequest } from './api';
import { activateLocale } from './i18n';


const WELCOME = '/accounts?fastPass=1';

function AppRoutes({ loggedIn = false }: { loggedIn?: boolean }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={WELCOME} replace />} />

        <Route path="signin" element={<Session.Signin to={WELCOME} />} />
        <Route path="signup" element={<Session.Signup />} />
        <Route path="forgot" element={<Session.ForgotPassword />} />
        <Route path="signout" element={<Session.Signout />} />

        <Route path="users">
          <Route path=":user">
            <Route path="reset" element={<Session.ResetPassword />} />
            <Route path="invite" element={<Session.Invite />} />
            <Route path="confirm" element={<Session.ConfirmEmail />} />
          </Route>
        </Route>

        <Route path="404" element={<NotFound />} />
      </Routes>

      <RestrictedRoutes only={loggedIn} redirect="/signin">
        <Route path="accounts">
          <Route path="" element={<Accounts.List />} />
          <Route path="new" element={<Accounts.New />} />
        </Route>

        <Route path=":account">
          <Route path="access" element={<Accounts.Access />} />

          <Route path="reports">
            <Route path="" element={<Reports.List />} />
            <Route path="new" element={<Reports.New />} />

            <Route path=":report">
              <Route path="" element={<Navigate to="campaigns" replace />} />

              <Route path="settings">
                <Route path="" element={<Reports.Edit />} />
                <Route path="forms">
                  <Route path="new" element={<FormBuilder.New />} />
                  <Route path=":code" element={<FormBuilder.Edit />} />
                </Route>
                <Route path="segments">
                  <Route path="new" element={<Segments.New />} />
                  <Route path=":segmentId" element={<Segments.Edit />} />
                </Route>
              </Route>

              <Route path="campaigns">
                <Route path="" element={<Campaigns.Current />} />
                <Route path=":scenario/:period">
                  <Route path="select" element={<Campaigns.Select />} />
                  <Route path="" element={<Campaigns.View />} />

                  <Route path=":form/:pivot">
                    <Route path="" element={<Campaigns.FormView />} />
                    <Route path="input" element={<Campaigns.FormInput />} />
                    <Route path="select" element={<Campaigns.Select />} />

                    <Route path=":segment">
                      <Route path="" element={<Campaigns.FormView />} />
                      <Route path="input" element={<Campaigns.FormInput />} />
                      <Route path="select" element={<Campaigns.Select />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </RestrictedRoutes>
    </BrowserRouter>
  );
}

export default function App(): React.ReactElement {
  const context = useAppReducer();
  const [state] = context;

  const loggedIn = !!state.session;
  const token = state.session?.token;
  const { locale } = state;

  const fetcher = apiRequest('get', { token });
  useEffect(() => { activateLocale(locale) }, [locale]);

  return (
    <AppContext.Provider value={context}>
      <I18nProvider i18n={i18n}
                    forceRenderOnLocaleChange={false}>
        <SWRConfig value={{ fetcher, revalidateOnFocus: false }}>
          <Suspense fallback={<Loading />}>
            <AppRoutes loggedIn={loggedIn} />
          </Suspense>
        </SWRConfig>
      </I18nProvider>
    </AppContext.Provider>
  );
}
