import React from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { Box, Text } from '@just/jui';
import LocaleSwitch from './LocaleSwitch';
import Logo from './Logo';


interface Props {
  admin?: boolean;
  buttons?: React.ReactElement|false;
  context?: React.ReactElement;
  children: React.ReactNode;
}

export default function Layout({
  admin = true,
  buttons,
  context,
  children
}: Props): React.ReactElement<Props> {
  const { report } = useParams();

  return (
   <Box horizontal
         align="center"
         height="screen"
         maxWidth="l"
         overflow="auto"
         justify="center"
         alignItems="stretch"
         background="white"
         border="0 0 0 w3"
         borderColor="accent-2"
         pane="shadow">
      <Box vertical
           width="s"
           alignItems="stretch"
           background="accent-1"
           color="light-2"
           spacing="l">

           <Box padding="m"
                spacing="m"
                height="s"
                vertical
                color="light-2">
             {buttons ? buttons : null}
           </Box>

           <Box vertical alignItems="stretch" flex="1">
             <Box margin="l">
               <Box margin="m">
                 <NavItem to={(r, a) => r ? reportUrl(a, r, 'campaigns')
                                          : accountUrl(a, 'reports')}
                          context={context}>
                   <Trans>Reporting</Trans>
                 </NavItem>
                 {
                   admin && report &&
                     <NavItem to="settings" context={context}>
                       <Trans>Settings</Trans>
                     </NavItem>
                 }
               </Box>
               <Box padding="s m"><LocaleSwitch /></Box>
               {
                 admin &&
                   <NavItem to={(_, account) => accountUrl(account, 'access')}
                            context={context}>
                     <Trans>Access</Trans>
                   </NavItem>
               }
               <Text padding="s m"caps block color="light-2">
                 <Link to="/accounts"><Trans>Accounts</Trans></Link>
               </Text>
             </Box>
           </Box>

           <Logo />
      </Box>

      <Box vertical padding="m l" flex="1" overflow="auto">
        {children}
      </Box>
    </Box>
  );
}

function Section({ label, link, active, children }: {
  label: string|React.ReactElement,
  link?: string,
  active?: boolean,
  children: React.ReactNode,
}) {
  return (
    <>
      <Box padding="s m"
           color={active && 'dark-1'}
           background={active && 'light-1'}>
        <Text caps>
          { link
              ? <Link to={link}>{label}</Link>
              : label
          }
        </Text>
      </Box>
      { (active || active === undefined) && children &&
          <Box padding="m">
            <Box padding="0 0 0 s" spacing="m">{children}</Box>
          </Box>
      }
    </>
  );
}

function NavItem({ children: label, context, to }: {
  to: string|((report: string, account: string) => string),
  context?: React.ReactElement,
  children: React.ReactElement|string,
}) {
  const { account, report } = useParams();
  const { pathname } = useLocation();

  const url = typeof to === 'function'
    ? to(report, account)
    : to.startsWith('../')
        ? accountUrl(account, to.substr(3))
        : reportUrl(account, report, to)

  const active = pathname.startsWith(url);

  return  (
    <Section label={label} link={url} active={active} >
      {context}
    </Section>
  );
}

function accountUrl(account: string, to: string) {
  return `/${account}/${to}`;
}

function reportUrl(account: string, report: string, to: string) {
  return accountUrl(account, `reports/${report}/${to}`);
}
