import React, { useState, useEffect } from 'react';
import { Trans, t } from '@lingui/macro';
import {
  Box,
  Text,
  Button,
  Field,
  Select,
  Fieldset,
  Checkbox
} from '@just/jui';
import { Table, AccessListEditor } from '../components';
import { Asset, Segment, Group } from '../types';
import { useAPI } from '../api';


interface Props {
  asset: Asset;
  segment: Segment;
  onSave?: (asset: Asset) => void;
  onCancel?: () => void;
}

export default function AssetRow({
  asset: initialAsset,
  segment,
  onSave,
  onCancel
}: Props): React.ReactElement<Props> {
  const [api] = useAPI({ withReport: false });
  const [asset, setAsset] = useState(initialAsset);

  useEffect(() => { load() }, [initialAsset]);

  const url = `/segments/${segment.id}/assets/${asset.id || ''}`;

  return (
    <Table.Form
      buttons={<>
        <Button icon="clear" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button icon="done" onClick={save} primary>
          <Trans>Save</Trans>
        </Button>
      </>}>
      <Box padding="s"
           spacing="l">
        <Box horizontal
             width="80"
             justify="start"
             alignItems="start"
             spacing="m">

          <Field label={t`Code`}
                 width="10"
                 required
                 value={asset.code}
                 onChange={code => change({ code })} />

          <Field label={t`Name`}
                 width="60"
                 required
                 value={asset.name}
                 onChange={name => change({ name })}
                 suffix={
                   <Checkbox
                     label={t`Validate ?`}
                     value={asset.isValidatable}
                     onChange={isValidatable => change({ isValidatable })} />
                 }/>

          <Field label={t`Group`}
                 width="20"
                 required
                 placeholder={t`Not grouped`}
                 value={asset.group}
                 onChange={group => change({ group })} />

          <Field label={t`Order`}
                 width="10"
                 numeric
                 placeholder={t`Auto`}
                 value={`${asset.order}`}
                 onChange={order => change({ order })} />
        </Box>
        <AccessListEditor accessList={asset.accessList || {}}
                          onChange={accessList => change({ accessList })} />
      </Box>
    </Table.Form>
  );

  async function load() {
    if (asset.accessList || !asset.id) return;
    try {
      setAsset(await api.get(url));
    } catch (e) { console.error(e) }
  }

  async function save() {
    if (!asset.code || !asset.name) return;

    try {
      onSave?.(await (asset.id
        ? api.patch(url, { asset })
        : api.post(url, { asset }))
      );
    } catch (e) {
      console.error(e);
    }
  }

  function change(attrs: Partial<Asset>) {
    setAsset({ ...asset, ...attrs });
  }
}
