export type ReportPeriod = 'monthly'|'daily'|'weekly'|'quarterly'|'annually';

export function shiftPeriod(
  period: Date,
  mod: number|string|undefined,
  type: ReportPeriod
): Date {
  if (!mod) return period;

  const year = period.getUTCFullYear();
  const month = period.getUTCMonth();
  const date = period.getUTCDate();

  if (typeof mod === 'string') {
    switch (mod) {
      case 'C': return period;
      case 'LY': return normalizePeriod(utc(year - 1, month, date), type);
      case 'LQ': {
        const result = shiftPeriod(period, -1, 'quarterly');
        result.setDate(date);
        return normalizePeriod(result, type);
      }
      case 'LM': return normalizePeriod(utc(year, month - 1, date), type);
      case 'LP': return shiftPeriod(period, -1, type);
      case 'NP': return shiftPeriod(period, +1, type);
      case 'ME': return normalizePeriod(utc(year, month + 2, -1), type);
      case 'QE': return shiftPeriod(
        normalizePeriod(utc(year, month), 'quarterly'), 'ME', type
      );
      case 'YE': return normalizePeriod(utc(year, 11, 31), type);
      default: return normalizePeriod(mod, type);
    }
  }

  switch (type) {
    case 'weekly': return utc(year, month, date + mod * 7);
    case 'quarterly': return utc(year, month + mod * 3);
    case 'annually': return utc(year + mod);
    case 'monthly': return utc(year, month + mod)
    case 'daily':  return utc(year, month, date + mod);
  }
}

export function parsePeriod(code: string): Date|null {
  const year = parseInt(code.slice(0, 2));
  const month = parseInt(code.slice(2, 4));
  const date = parseInt(code.slice(4, 6));

  if ((!year) ||
      (!month || month < 1 || month > 12) ||
      (date && date > 31)) return null;

  return utc(
    year < 1000 ? year + 2000 : year,
    month - 1,
    date || 1
  );
}

export function normalizePeriod(
  periodOrCode: Date|string,
  type: ReportPeriod
): Date {
  let period = typeof periodOrCode === 'string'
    ? parsePeriod(periodOrCode) : periodOrCode;

  if (!period) period = new Date();

  const year = period.getUTCFullYear();
  const month = period.getUTCMonth();
  const date = period.getUTCDate();
  const day = period.getUTCDay();

  switch (type) {
    case 'weekly': return utc(year, month, date - day)
    case 'quarterly': return utc(year, (Math.ceil((month + 1) / 3) * 3) - 1)
    case 'annually': return utc(year);
    case 'monthly': return utc(year, month);
    case 'daily': return utc(year, month, date)
  }
}

export function utc(
  year: number,
  month: number = 0,
  date: number = 1
): Date {
  return new Date(Date.UTC(year, month, date));
}
