import React, { useState } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import {
  Box,
  Text,
  Button,
  Field,
  Checkbox,
  validators as v
} from '@just/jui';
import { Table } from '../components';
import { Group, Map } from '../types';
import { useAPI } from '../api';


interface Props {
  access?: Access,
  onSave: (a: Access) => void;
  onCancel: () => void;
  onDelete?: (a: Access) => void;
}

export interface Access {
  userId: string;
  group?: string;
  groupId: number;
  isAdmin: boolean;
}

export default function UserAccessForm({
  access: initialAccess,
  onSave,
  onCancel,
  onDelete
}: Props): React.ReactElement<Props> {
  const { account } = useParams();
  const { data: groups, mutate } =
    useSWR<Group[]>(`/accounts/${account}/groups`);

  const [access, setAccess] =
    useState<Access>(initialAccess || {
      userId: '',
      groupId: 0,
      isAdmin: false
  });

  const [errors, setErrors] = useState<Map>({});
  const [newGroup, setNewGroup] = useState('');

  const [api] = useAPI();
  const userGroup = groups?.find(g => g.id === access.groupId);

  const isAdmin = newGroup || !userGroup
    ? <Checkbox small
                label={t`Admin?`}
                onChange={isAdmin => change({ isAdmin })} />
    : userGroup.isAdmin &&
      <Text small strong><Trans>Admin</Trans></Text>;

  return (
    <Table.Form buttons={<>
        { initialAccess && onDelete &&
          <Button margin="l" onClick={() => onDelete(access)}>
            <Trans>Delete</Trans>
          </Button>
        }
        <Button onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button primary onClick={save}>
          {
            initialAccess
              ? <Trans>Modify</Trans>
              : <Trans>Invite</Trans>
          }
        </Button>
      </>}>
      <Box padding="m" spacing="l">
        <Box horizontal justify="start" alignItems="start" spacing="m">
          {!initialAccess &&
            <Field label={t`E-Mail`}
                   flex="1"
                   value={access.userId}
                   errors={errors.userId}
                   required
                   validate={v.email}
                   onChange={userId => change({ userId })}
                   placeholder={t`Type e-mail of user to invite`} />
          }
          <Field label={initialAccess
                   ? t`Access group for ${access.userId}`
                   : t`Access group`}
                 value={groups?.find(g => g.id === access.groupId)?.name}
                 flex="1"
                 errors={errors.groupId}
                 from={groups}
                 onChange={g => change({ groupId: g?.id })}
                 onNewSuggestion={onNewGroup}
                 validateNewSuggestion={v.length(3, 20)}
                 placeholder={t`No access`}
                 suffix={isAdmin}/>
        </Box>
      </Box>
    </Table.Form>
  );

  function change(attrs: Partial<Access>) {
    if (attrs.groupId) setNewGroup('');
    if (attrs.isAdmin && !newGroup) {
      attrs.groupId = groups?.find(g => g.isAdmin)?.id;
      attrs.isAdmin = true;
    }

    setAccess({ ...access, ...attrs });
  }

  function onNewGroup(name: string) {
    const term = name.toLowerCase().trim();
    const found = groups?.find(g => g.name.toLowerCase() === term);

    if (found) change({ groupId: found.id });
    else {
      setNewGroup(name);
      change({ groupId: 0 });
    }
  }

  async function createNewGroup() {
    try {
      const attrs = { name: newGroup, isAdmin: access.isAdmin };
      const group: Group = await api.post('/groups', { group: attrs });

      mutate((groups || []).concat(group), false);
      setNewGroup('');
      return group;
    } catch (e) {
      setErrors({ group: t`Could not create a new group` });
      return null;
    }
  }

  async function save() {
    const { groupId, userId } = access;

    const group = access.groupId
        ? groups?.find(g => g.id === groupId)
        : await createNewGroup();

    if (!userId) return setErrors({ userId: t`is required`});
    if (!group) return setErrors({ groupId: t`is required` });

    const body = { access: { userId, groupId: group.id }};
    try {
      if (!initialAccess) await api.post('/users', body);
      else await api.patch(`/users/${encodeURIComponent(userId)}`, body);

      onSave({
        ...body.access,
        group: group.name,
        isAdmin: group.isAdmin
      });
    }
    catch (e) { console.error(e); }
  }
}
