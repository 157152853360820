import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Trans, t } from '@lingui/macro';
import { useHotkeys } from 'react-hotkeys-hook';
import { Box, Text, Button, Field, Checkbox } from '@just/jui';
import { Layout, Loading, Table } from '../components';
import { User, Group } from '../types';
import { useAPI } from '../api';
import UserAccessForm, { Access } from './UserAccessForm';


const NEW = 'new';

export default function UsersList(): React.ReactElement {
  const [api] = useAPI();
  const { account } = useParams();
  const { data: users, mutate } =
    useSWR<Access[]>(`/accounts/${account}/users`);

  const [selectedUser, setSelectedUser] = useState('');
  useHotkeys('escape', () => setSelectedUser(''));

  return (
    <Layout buttons={
        <Button icon="person_add" onClick={() => setSelectedUser(NEW)}>
          <Trans>Add user</Trans>
        </Button>
      }>
      <Box width="70">
        <Table.Header>
          <Text h4 caps strong color="dark-3" width="3">#</Text>
          <Text h4 caps strong flex="1"><Trans>E-mail</Trans></Text>
          <Text h4 caps width="15">
            <Trans>Admin?</Trans>
          </Text>
          <Text h4 caps width="35">
            <Trans>Access Group</Trans>
          </Text>
        </Table.Header>

        {
          users && users.map((access, i) =>
            selectedUser === access.userId
              ? <UserAccessForm key={access.userId}
                                access={access}
                                onSave={change}
                                onDelete={revoke}
                                onCancel={hideEditor} />
              : <Table.Row key={access.userId}
                           onClick={() => setSelectedUser(access.userId)}>
                  <Text block width="3" color="dark-3">{i + 1}</Text>
                  <Text block flex="1">{access.userId}</Text>
                  <Text block width="15" color="dark-2" small caps>
                    {access.isAdmin ? t`YES` : t`NO`}
                  </Text>
                  <Text block width="35" color="dark-2">
                    {access.group}
                  </Text>
                </Table.Row>

          )
        }
        {selectedUser === NEW &&
          <UserAccessForm onSave={grant}
                          onCancel={hideEditor} />
        }
        {
          !!selectedUser && <Box overlay />
        }
      </Box>
    </Layout>
  );

  async function grant(a: Access) {
    mutate((users || []).concat(a));
    setSelectedUser('');
  }

  async function change(a: Access) {
    if (!users) return;
    mutate(users.map(u => u.userId === a.userId ? a : u));
    setSelectedUser('');
  }

  async function revoke(a: Access) {
    if (!users) return;
    try {
      await api.delete(`/users/${encodeURIComponent(a.userId)}`);
      mutate(users.filter(u => u.userId === a.userId));
      setSelectedUser('');
    }
    catch (e) { console.error(e); }
  }

  function hideEditor() {
    setSelectedUser('');
  }
}
