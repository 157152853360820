import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import {
  Box,
  Button,
  Field,
  Choice,
  Fieldset,
  Checkbox,
  validators as v
} from '@just/jui';
import { ExpressionEditor } from '../components';
import {
  Dispatch,
  changeRow,
  selectRow,
  insertNewRow,
  formatCode
} from './reducer';
import { Row, Form } from '../types';
import { resourceURL } from '../api';


const ACTIVE_ROW = -1;

interface Props {
  row: Row;
  form: Form;
  dispatch: Dispatch
}

export default function RowEditor({ row, form, dispatch }: Props) {
  const params = useParams();

  const rows = form.rows;
  const codes = rows.map(r => r.row === row.row ? ACTIVE_ROW : r.row);
  const aliases = rows.map(r => r.alias === row.alias ? '' : r.alias);

  const otherRows = useMemo(() => rows.filter(r => r !== row).map(r => {
    const code = formatCode(r.row, form.code);
    return { id: code, name: `[${code}] ${r.name}`}
  }), [rows]);

  const aggregations: { [key: string]: string } = useMemo(() => ({
   '0': t`Dismissed at consolidation`,
   'FX': t`Computed by formula`,
   'SUM': t`Sum all reported values`,
   'MIN': t`Get minimum value`,
   'MAX': t`Get maximum value`,
   'AVG': t`Get average value`,
   'WAVG': t`Get average weighted by`
  }), []);

  return (
    <Box zIndex="1"
         background="dark-6"
         padding="0 0 l 0"
         border="0 w3"
         borderColor="dark-5"
         borderCollapse>
      {
        row.row > 0 &&
          <Box position="top-right" background="white" padding="0 s 0 0">
            <Button icon="arrow_upward" onClick={insertBefore}>
              <Trans>Insert row before</Trans>
            </Button>
          </Box>
      }
      {
        row.row < 999 &&
          <Box position="bottom-right" background="white" padding="0 s 0 0">
            <Button icon="arrow_downward" onClick={insertAfter}>
              <Trans>Insert row after</Trans>
            </Button>
          </Box>
      }
      <Box horizontal justify="end" padding="s s 0 0">
        <Button icon="visibility_off" onClick={hide}>
          <Trans>Hide</Trans>
        </Button>
      </Box>

      <Box horizontal padding="s"
                      spacing="xl"
                      justify="start"
                      alignItems="start">
        <Box width="50" spacing="l">
          <Box horizontal justify="start" alignItems="start" spacing="m">
            <Field label={t`Code`}
                   width="20"
                   numeric
                   validate={[v.presence, v.between(1, 999), v.unique(codes)]}
                   value={formatCode(row.row)}
                   onChange={row => change({ row })} />

            <Field label={t`Name`}
                   flex="1"
                   required
                   value={row.name}
                   onChange={name => change({ name })} />
          </Box>

         <Field label={t`Alias`}
                value={row.alias}
                placeholder={t`${formatCode(row.row, form.code)}`}
                validate={[v.unique(aliases),
                           v.format(/^\w{2,15}$/,
                                    t`one word (2 to 15 characters)`)
                          ]}
                onChange={alias => change({ alias })}
                suffix={
                   <Checkbox
                     small
                     label={t`Intragroup?`}
                     value={row.intragroup}
                     onChange={intragroup => change({ intragroup })} />
                }/>

          <Box horizontal justify="start" alignItems="start" spacing="m">
            <Field label={t`Consolidation method`}
                   flex="1"
                   required
                   value={aggregations[row.aggregate]}
                   from={aggregations}
                   onChange={({ id }) => change({ aggregate: id })} />


            {
              row.aggregate === 'WAVG' &&
                <Field
                  width="30"
                  label={t`Weight by`}
                  from={otherRows}
                  required
                  value={row.aggregateWeight}
                  onChange={({ id }) => change({ aggregateWeight: id })} />
            }
          </Box>

          <Field label={t`Formula expression`}
                 placeholder={t`Ex: Sales + ISF050`}
                 value={row.formula}
                 onChange={formula => change({ formula })}
                 input={ExpressionEditor}
                 inputProps={{ rows, multiline: true }} />

          <Field label={t`Validation expression`}
                 placeholder={t`Ex: Current Assets or ISF399`}
                 value={row.validator}
                 onChange={validator => change({ validator })}
                 input={ExpressionEditor}
                 inputProps={{
                   rows: resourceURL(params)('/rows'),
                   multiline: true
                 }}/>

        </Box>
        <Fieldset label={t`Style options`} spacing="l">
          <Choice value={row.style}
                  onChange={style => change({ style })}>
          {{
            'regular':   t`Regular`,
            'strong':    t`Bold`,
            'highlight': t`Bold & Highlight`,
            'border':    t`Bold & Highlight & Border`,
          }}
          </Choice>
          <Checkbox label={t`Yes, add a separator`}
                    value={row.separated}
                    onChange={separated => change({ separated })}/>
        </Fieldset>
      </Box>
    </Box>
  );

  function hide() {
    dispatch(selectRow(-1));
  }

  function change(attrs: Partial<Row>) {
    if (attrs.alias === '') attrs.alias = null;
    if (attrs.formula === '') attrs.formula = null;
    if (attrs.validator === '') attrs.validator = null;

    if (attrs.formula) {
      attrs.aggregate = 'FX';
      attrs.aggregateWeight = null;
    }

    if (attrs.intragroup) {
      attrs.aggregate = '0';
      attrs.aggregateWeight = null;
    }

    if (attrs.aggregateWeight === '' ||
        row.aggregate !== 'WAVG') attrs.aggregateWeight = null;

    if (attrs.aggregate === 'WAVG') {
      const by = otherRows[0]?.id;
      if (!by) attrs.aggregate = 'AVG';
      else attrs.aggregateWeight = by;
    }

    dispatch(changeRow(row.row, attrs));
  }

  function insertAfter() {
    insert(1);
  }

  function insertBefore() {
    insert(-1);
  }

  function insert(mod: number) {
    const rowCode = row.row;
    const andCode = codes[codes.indexOf(ACTIVE_ROW) + mod] || rowCode + mod;

    const newCode = getPlaceBetween(rowCode, andCode);
    dispatch(insertNewRow(newCode));
  }

  function getPlaceBetween(self: number, next: number) {
    if (self === next) next = self + 1;
    const mod = Math.sign(next - self);
    const usedCodes = [...codes, row.row];

    let code = self + ((next - self) / 2 << 0);

    while (usedCodes.includes(code)) {
      code += mod;
      if ((code < 0) || (code > 999)) return 0;
    }

    return code;
  }
}
