import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { Box, Text } from '@just/jui';
import { Segment, Map } from '../types';
import { Loading } from '../components';


interface Props {
  segments?: Segment[];
  labels?: Map;
}

export default function FormsList({
  segments,
  labels = {}
}: Props): React.ReactElement<Props> {
  const navigate = useNavigate();

  return (
    <Box>
      <Text h2 color="dark-2" padding="0 0 m 0"><Trans>Segments</Trans></Text>
      <Box horizontal justify="start" wrap>
        {
          segments
            ? segments.map(s =>
                <Tile key={s.id}
                      href={`segments/${s.id}`}
                      label={labels[`${s.id}`]}>
                  {s.name}
                </Tile>
              )
            : <Loading>
                <Trans>Loading list of segments. Please wait...</Trans>
              </Loading>
        }
      </Box>
      <Box>
        <Tile href="segments/new">
          <Text caps strong center color="accent-2">
            <Trans>Create a new segment</Trans>
          </Text>
        </Tile>
      </Box>
    </Box>
  );
}

interface TileProps {
  href: string;
  label?: string;
  children: string|React.ReactElement;
}

function Tile({
  href,
  label,
  children
}: TileProps): React.ReactElement<TileProps> {
  const navigate = useNavigate();

  return (
    <Box width="30" height="s" padding="0 l l 0">
      <Box pane="brackets"
           vertical
           stretch
           spacing="s"
           cursor="pointer"
           justify="center"
           onClick={go}>
        {
          typeof children === 'string'
            ? <>
                <Text block strong color="dark-1">
                  {children}
                </Text>
                { label && <Text color="dark-3">{label}</Text> }
              </>
            : children
        }
      </Box>
    </Box>
  );

  function go() {
    navigate(href);
  }
}
