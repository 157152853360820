import React from 'react';
import { Box, Text } from '@just/jui';
import { t } from '@lingui/macro';
import { Row } from '../types';
import { formatCode } from './reducer';


interface Props {
  row: Row,
  onClick?: (row: Row) => void;
}

function RowLine({ row, onClick }: Props) {
  const { box, title } = styles[row.style as keyof typeof styles] ||
                         styles.regular;
  return (
    <Box horizontal
         spacing="s"
         padding="s 0"
         borderCollapse
         onClick={() => onClick?.(row)}
         {...box}>
      <Text center small width="5">{formatCode(row.row)}</Text>
      <Text width="30 auto@sm" {...title}>{row.name}</Text>
      <Text small center width="5 auto@sm">{row.intragroup && t`IGR`}</Text>
      <Text small width="40 100@sm">
        {row.formula}
      </Text>
      <Text small width="15 0@sm">
        {
          row.validator &&
            <>
              <Text color="light-3">= </Text>
              {row.validator}
            </>
        }
      </Text>
    </Box>
  );
}

export default React.memo(RowLine);

const styles = {
  regular: {
    box: { border: 'dotted 0 0 0' },
    title: { strong: false }
  },
  strong: {
    box: { border: 'dotted 0 0 0' },
    title: { strong: true }
  },
  highlight: {
    box: {
      border: 'dotted 0 0 0',
      background: 'dark-6'
    },
    title: { strong: true }
  },
  border: {
    box: {
      border: '0 w2',
      borderColor: 'dark-4',
      background: 'dark-6',
    },
    title: { strong: true }
  }
} as const;
