import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { Loading } from '../components';
import { useSignIn } from './hooks';


export default function ConfirmEmail(): React.ReactElement {
  const { user: userId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const signIn = useSignIn({ to: '/accounts' });

  useEffect(() => { confirmEmail() }, []);

  return (
    <Loading>
      <Trans>Checking your email confirmation. Please wait...</Trans>
    </Loading>
  );

  async function confirmEmail() {
    const confirmToken = searchParams.get('token') as string;
    try { await signIn({ login: userId, confirmToken }) }
    catch (e) { navigate('/signin'); }
  }
}
