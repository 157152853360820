import React from 'react';
import { Trans } from '@lingui/macro';
import { Box, Text } from '@just/jui';


export default function Logo() {
  return (
    <Text block caps color="light-4" padding="m">
       <Trans>Astring<br/>Reporting</Trans>
    </Text>
  );
}
