import React from 'react';
import { t } from '@lingui/macro';
import { Box, Text,  } from '@just/jui';
import { Table } from '../components';
import { Row } from '../types';


interface Props {
  row: Row;
  warning?: React.ReactNode;
  children?: React.ReactNode;
}

export default function FormRow({
  row,
  warning,
  children
}: Props): React.ReactElement<Props> {
  const [style, weight] = getStyle(row);

  return (
    <Table.Row style={style}>
      <Text block width="10" small color="dark-3">{row.code}</Text>
      <Box width="35" spacing="xs">
        <Text block weight={weight}>{row.name}</Text>
        {warning}
      </Box>
      <Text block width="3">{row.intragroup && t`IGR`}</Text>
      {children}
    </Table.Row>
  );
}

function getStyle({ style }: Row): [
  'regular'|'highlight'|'border',
  'normal'|'bold'|'medium'
] {
  switch (style) {
    case 'strong': return ['regular', 'medium'];
    case 'highlight': return ['highlight', 'medium'];
    case 'border': return ['border', 'bold'];
    default: return ['regular', 'normal'];
  }
}
