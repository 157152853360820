import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { Box, Text, Field, Button, validators as v } from '@just/jui';
import { Segment, Map, ReportPeriod } from '../types';
import { useAPI, FieldErrors } from '../api';
import { periodNames } from '../utils';
import { Frame } from '../components';


interface Props {

}

interface Report {
  code: string;
  name: string;
  period: ReportPeriod;
  pivot: string|number;
  scenario: string|number;
}

const blankReport: Report = {
  code: '',
  name: '',
  pivot: '',
  period: 'monthly',
  scenario: ''
};

export default function ReportForm({

}: Props): React.ReactElement<Props> {
  const [segments, setSegments] = useState<Map>({});
  const [report, setReport] = useState<Report>(() => ({ ...blankReport }));
  const [errors, setFieldErrors] = useState<FieldErrors>(null);
  const [api, loading] = useAPI({ setFieldErrors, withReport: false });
  const navigate = useNavigate();

  useEffect(() => { load() }, []);

  const PERIODS = periodNames();

  return (
    <Frame title={t`New Reporting`}>
      <Box horizontal
           padding="l 0 0 0"
           spacing="m"
           alignItems="start">
        <Field label={t`Code`}
               width="30"
               placeholder={t`Ex: ifrs-new`}
               required
               convert="lowercase"
               validate={[v.slug, v.length(3, 20)]}
               value={report.code}
               errors={errors?.code}
               onChange={code => change({ code })} />
        <Field label={t`Name`}
               flex="1"
               placeholder={t`Ex: IFRS Reporting`}
               required
               validate={[v.length(3, 100)]}
               value={report.name}
               errors={errors?.name}
               onChange={name => change({ name })} />
      </Box>

      { Object.keys(segments).length > 0 &&
        <Box spacing="m">
          <Field
            label={t`Pivot by`}
            from={segments}
            placeholder={t`Select existing, type a new name, or leave empty`}
            value={segments[report.pivot] || report.pivot as string}
            onChange={({ id: pivot }) => change({ pivot })}
            onNewSuggestion={pivot => change({ pivot })} />

          <Field
            label={t`Scenarios from`}
            from={segments}
            placeholder={t`Select existing, type a new name, or leave empty`}
            value={segments[report.scenario] || report.scenario as string}
            onChange={({ id: scenario }) => change({ scenario })}
            onNewSuggestion={scenario => change({ scenario })} />
        </Box>
      }

      <Box horizontal
           padding="l 0 0 0"
           spacing="m"
           alignItems="start">
        <Field label={t`Reporting period`}
               width="30"
               value={PERIODS[report.period]}
               required
               errors={errors?.period}
               from={PERIODS}
               onChange={({ id: period }) =>
                 change({ period: period as ReportPeriod })} />
        <Field label={t`Template`}
               flex="1"
               from={[t`Blank`]}
               value={t`Blank`}
               onChange={template => {}} />
      </Box>


      <Box horizontal justify="center" padding="l 0 0 0">
        { !loading &&
          <Button onClick={create}>
            <Trans>Create reporting</Trans>
          </Button>
        }
      </Box>
    </Frame>
  );

  async function load() {
    try {
      setSegments(Object.fromEntries(
        (await api.get<Segment[]>('/segments')).map(s => [`${s.id}`, s.name])
      ))
    }
    catch (e) {}
  }

  async function create() {
    const warnings = {} as { [key in keyof Report]?: string };
    if (!report.code) warnings.code = t`is required`;
    if (!report.name) warnings.name = t`is required`;

    if (Object.keys(warnings).length > 0) {
      setFieldErrors(warnings);
      return;
    }

    console.log(report);

    if (!report.pivot) report.pivot = t`Pivot Segment`;
    if (!report.scenario) report.scenario = t`Scenario`;

    try {
      const { code } = await api.post('/reports', { report });
      navigate(`../${code}/settings`);
    }
    catch (e) {}
  }

  function change(input: Partial<Report>) {
    setFieldErrors(null);
    setReport({ ...report, ...input });
  }
}
