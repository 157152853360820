import React from 'react';
import { t } from '@lingui/macro';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useSignIn } from './hooks';
import { useAPI } from '../api';
import { Loading } from '../components';
import UserForm from './UserForm';


export default function Invite(): React.ReactElement {
  const { user: userId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const signIn = useSignIn({ to: '/accounts?fastPass' });
  const [api, loading] = useAPI({ load, withAccount: false });

  const token = searchParams.get('token') as string;

  async function load() {
    try { await api.get(`/users/${encodeURIComponent(userId)}?token=${token}`); }
    catch (e) { navigate('/signup', { replace: true }); }
  };

  return loading
    ? <Loading>Checking invitation. Please wait...</Loading>
    : <UserForm
        id={userId}
        token={token}
        title={t`Astring Reporting — Welcome`}
        onSave={({ id: login }, password) =>
                signIn({ login, password: password! }) } />;
}
