import React, { ReactElement, useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import { RouteProps } from 'react-router';


interface Props {
  only?: boolean,
  redirect?: string,
  children: ReactElement<RouteProps>[]|ReactElement<RouteProps>;
}

export default function RestrictedRoutes({
  only = false,
  redirect = '/signin',
  children
}: Props): ReactElement {
  const { pathname } = useLocation();

  function restrict(node: React.ReactNode): React.ReactNode {
    if (!React.isValidElement(node) || node.type !== Route)
      return node;

    const route = node as React.ReactElement<RouteProps>;
    let { path, element, children } = route.props;

    if (children) children = React.Children.map(children, restrict);
    else if (element) {
      element = only
        ? route.props.element
        : <Navigate to={`${redirect}?to=${pathname}`} />
    }

    return React.cloneElement(route, { key: path, element }, children);
  }

  return (
    <Routes>
      {React.Children.map(children, restrict)}
    </Routes>
  );
}
