import React from 'react';
import {
  useLocation,
  useNavigate,
  Navigate
} from 'react-router-dom';
import { t } from '@lingui/macro';
import { Form } from '../types';
import { useAPI } from '../api';
import FormEditor from './FormEditor';


type State = { code?: string };

export default function NewForm(): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const [api] = useAPI();

  const { code = '' }: State = location.state as State || {};

  if (!code) return <Navigate to="../.." />;
  const newForm: Form = { code, name: t`Untitled Form`, rows: [] };

  return <FormEditor form={newForm}
                     onSave={createForm}
                     onCancel={back} />

  async function createForm(form: Form) {
    try {
      await api.post(`/forms`, { form })
      back();
    }
    catch (e) { console.error(e); }
  }

  function back() {
    navigate('../..');
  }
}
